// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

const urlBase = window._env_.REACT_APP_SERVICES_URL || process.env.REACT_APP_SERVICES_URL;

async function getAllServices() {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  };

  const response = await fetch(`${urlBase}/registry/`, requestOptions);
  return await middlewareDefault(response);
  // if (!response.ok) {
  //   if (response.status === 401 || response.status === 403) {
  //     window.localStorage.removeItem('token');
  //     window.location = '/login';
  //   } else {
  //     throw new Error(response.statusText);
  //   }
  // }
  // return await response.json();
}

async function getServiceById(id) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  };

  const response = await fetch(`${urlBase}/registry/${id}`, requestOptions);

  return await middlewareDefault(response);
}

async function downloadWidget(documentId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  };

  const response = await fetch(
    `${urlBase}/registry/widgets/${documentId}/download`,
    requestOptions,
  );

  return await middlewareDefault(response);
}

async function callServiceEndpoint(rootUrl, path, requestMethod, returnJson = true) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: requestMethod,
    headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" },
  };

  const response = await fetch(`${rootUrl}${path}`, requestOptions);

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      window.localStorage.removeItem("token");
      window.location = "/login";
    } else {
      throw new Error(response.statusText);
    }
  }

  return (await returnJson) ? response.json() : response.blob();
}

export { getAllServices, getServiceById, callServiceEndpoint, downloadWidget };
