/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-plusplus */
import { message } from "antd";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FilesList } from "./FilesList/FilesList";
import styles from "./MultipleFileUploadField.module.scss";

const MAX_LENGTH = 60;
const EXTENSIONS = [".pdf", ".doc", ".docx", ".jpg", ".png", ".txt", ".html", ".csv", ".xls"];
const MAXFILES = 10;

// eslint-disable-next-line react/function-component-definition
const MultipleFileUploadField = ({
  acceptFiles,
  onDelete,
  handleUpdateAcceptFiles,
  extensions = EXTENSIONS,
  maxFiles = MAXFILES,
  showList = true,
}) => {
  // function MultipleFileUploadField({ handleUpdateAcceptFiles }) {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    handleUpdateAcceptFiles(acceptedFiles);
    // eslint-disable-next-line array-callback-return
    rejectedFiles.map(rejFile =>
      rejFile.errors.map(error => message.error(`${rejFile.file.name}: ${error.message}`)),
    );
  }, []);

  const nameLengthValidator = file => {
    if (file.name.length > MAX_LENGTH) {
      return {
        code: "name-too-large",
        message: `Name is larger than ${MAX_LENGTH} characters`,
      };
    }

    return null;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: extensions,
    maxSize: 100000 * 1024, // != 100mb
    maxFiles,
    validator: nameLengthValidator,
  });

  return (
    <>
      <div>
        <div {...getRootProps()} className={`${styles.dropzone} ${styles.flex_column}`}>
          <input {...getInputProps()} />
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-file-earmark"
              viewBox="0 0 16 16"
            >
              <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
            </svg>
          </div>
          <div>
            <strong>Solte aqui</strong> um ou mais arquivos ou
            <strong> clique </strong> nesta area para selecionar
          </div>
        </div>
      </div>
      {showList && <FilesList acceptFiles={acceptFiles} onDelete={onDelete} />}
    </>
  );
};

export { MultipleFileUploadField };
