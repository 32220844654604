/* eslint-disable import/no-unresolved */
import { PageLayout } from "components/PageLayout";
import React from "react";
import { UnorderedListOutlined, DatabaseOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { Documents } from "containers/Documents";
import { Templates } from "containers/Templates/Templates";

function Metadata() {
  return (
    <PageLayout
      title="Metadados"
      subTitle="Listagem, adição e edição de documentos e templates de metadados."
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          key="1"
          tab={
            <span>
              <UnorderedListOutlined />
              Listagem de Documentos
            </span>
          }
        >
          <Documents />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span>
              <DatabaseOutlined />
              Templates
            </span>
          }
        >
          <Templates />
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  );
}
export { Metadata };
