/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
import { DatePicker, Form, Input } from "antd";
import moment from "moment";
import styles from "./DynamicFormInput.module.scss";

function DynamicFormInput({ field, objectValue }) {
  switch (field.field_type) {
    case "text":
      return (
        <Form.Item
          label={field.field_name.replace(/_/g, " ")}
          name={[field.field_name, "value"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
        >
          <Input type="text" />
        </Form.Item>
      );
    case "textarea":
      return <Input.TextArea className={styles.textarea_AntdInput} type="textarea" />;
    case "integer":
      return (
        <Form.Item
          label={field.field_name.replace(/_/g, " ")}
          name={[field.field_name, "value"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
        >
          <Input type="number" />
        </Form.Item>
      );
    case "float":
      return (
        <Form.Item
          label={field.field_name.replace(/_/g, " ")}
          name={[field.field_name, "value"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
        >
          <Input type="number" step="0.1" />
        </Form.Item>
      );
    case "currency":
      return (
        <Form.Item
          label={field.field_name.replace(/_/g, " ")}
          name={[field.field_name, "value"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
        >
          <Input type="number" prefix="R$" />
        </Form.Item>
      );
    case "date":
      return (
        <Form.Item
          label={field.field_name.replace(/_/g, " ")}
          name={[field.field_name, "value"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
        >
          <DatePicker
            className={styles.data_placeholder_input}
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
          />
        </Form.Item>
      );
    default:
      return null;
  }
}

export { DynamicFormInput };
