/* eslint-disable react/prop-types */
import { Tag } from "antd";
import React from "react";

function TagRender({ label, closable, onClose }) {
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color="blue"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

export { TagRender };
