/* eslint-disable react/prop-types */
import { Menu, Typography } from "antd";
import React from "react";

// eslint-disable-next-line no-unused-vars
function MenuJobActions({ job, handleDelete, handleEdit, handlePause, handleReset }) {
  return (
    <Menu
      items={[
        {
          label: <Typography>Executar agora</Typography>,
          key: "0",
          onClick: () => handleReset(job),
        },
        {
          label: <Typography>Pausar</Typography>,
          key: "1",
          onClick: () => handlePause(job),
        },
        {
          label: <Typography>Editar</Typography>,
          key: "2",
          onClick: () => handleEdit(job),
        },
        {
          label: <Typography>Excluir</Typography>,
          key: "3",
          onClick: () => handleDelete(job),
        },
      ]}
    />
  );
}

export { MenuJobActions };
