/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable no-shadow */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import styles from "./ServiceDetails.module.scss";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Spin, Tabs, Table, Divider, Space, Button, message, Tooltip } from "antd";
import {
  OrderedListOutlined,
  FileOutlined,
  Html5Outlined,
  UnorderedListOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

// eslint-disable-next-line import/no-unresolved
import { Widget } from "components/Widget";
// eslint-disable-next-line import/no-unresolved
import { JsonModal } from "components/JsonModal";

import {
  callServiceEndpoint,
  downloadWidget,
  getServiceById,
  // eslint-disable-next-line import/no-unresolved
} from "api/Services/services.service";
// eslint-disable-next-line import/no-unresolved
import { ConfirmModal } from "components/ConfirmModal";

const sorterColumns = columnName => {
  if (columnName === "id") {
    return (a, b) => a.id - b.id;
  }
  if (columnName === "email") {
    return (a, b) => a.email.localeCompare(b.email);
  }
  if (columnName === "full_name") {
    return (a, b) => a.full_name.localeCompare(b.full_name);
  }
  if (columnName === "num_photos") {
    return (a, b) => a.num_photos - b.num_photos;
  }
  return undefined;
};

// eslint-disable-next-line react/function-component-definition
const ServiceDetailsPage = () => {
  const { Title } = Typography;

  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingListing, setLoadingListing] = useState(true);
  const [loadingWidgets, setLoadingWidgets] = useState(true);

  const [service, setService] = useState();

  const [documentation, setDocumentation] = useState();
  const [listing, setListing] = useState();
  const [widgets, setWidgets] = useState();

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [detailsData, setDetailsData] = useState();
  const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);

  const [currentRecord, setCurrentRecord] = useState();

  useEffect(() => {
    getServiceById(id)
      .then(response => {
        setService(response);
      })
      .catch(err => {
        navigate("404");
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (service) {
      processDocumentation();
      processWidgets();
      processListing();
    }
  }, [service]);

  const processDocumentation = () => {
    if (service.documentation_sufix === null || service.documentation_sufix === "") {
      setDocumentation(<p>Serviço não possui documentação disponível.</p>);
    }
    setDocumentation(
      <iframe
        frameBorder="0"
        title="documentation"
        src={service.documentation_sufix}
        width="100%"
        height={700}
      />,
    );
  };

  const generateSnippets = async documentId => {
    const res = await downloadWidget(documentId);
    const widgetData = await res.text();

    let js = widgetData.split("<script>")[1];
    if (js) {
      js = `<script>${js.split("</script>")[0]}</script>`;
    }

    let css = widgetData.split("<style>")[1];
    if (css) {
      css = `<style>${css.split("</style>")[0]}</style>`;
    }

    const html = widgetData.substring(
      widgetData.indexOf("<body>") + 6,
      widgetData.indexOf("<script>"),
    );

    return { html, css, js };
  };

  const processWidgets = async () => {
    if (service.widgets) {
      setLoadingWidgets(true);
      const sections = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const widget of service.widgets) {
        const snippetsData = await generateSnippets(widget.document_id);

        sections.push({
          title: widget.title,
          description: widget.description,
          apiUrl: service.root_url,
          documentId: widget.document_id,
          snippets: [
            {
              title: "HTML",
              language: "html",
              eventKey: "html",
              value: snippetsData.html,
            },
            {
              title: "JavaScript",
              language: "html",
              eventKey: "js",
              value: snippetsData.js,
            },
            {
              title: "CSS",
              language: "html",
              eventKey: "css",
              value: snippetsData.css,
            },
          ],
        });
      }

      setWidgets(<Widget sections={sections} />);
      setLoadingWidgets(false);
    } else {
      setWidgets(<p>Não há widgets disponíveis para esse serviço.</p>);
    }
  };

  const processListing = () => {
    const listingEndpoint = service.available_endpoints.filter(x => x.request_type === "LISTING");
    if (listingEndpoint.length > 0) {
      const retrieveOneEndpoint = service.available_endpoints.filter(
        x => x.request_type === "RETRIEVE_ONE",
      );
      const deleteEndpoint = service.available_endpoints.filter(
        x => x.request_type === "DELETE_ONE",
      );
      const downloadEndpoint = service.available_endpoints.filter(
        x => x.request_type === "DOWNLOAD",
      );

      callServiceEndpoint(
        service.root_url,
        listingEndpoint[0].path,
        listingEndpoint[0].request_method,
      )
        .then(response => {
          if (response.length > 0) {
            // eslint-disable-next-line arrow-body-style
            const columns = Object.keys(response[0]).map(col => {
              return {
                title: col.replace("_", " "),
                dataIndex: col,
                key: col,
                sorter: sorterColumns(col),
              };
            });
            columns.push({
              title: "actions",
              dataIndex: "actions",
              key: "actions",
              render: (text, record) => (
                <Space size="middle">
                  {retrieveOneEndpoint.length > 0 && detailsLoading === false && (
                    <Tooltip placement="topLeft" title="detalhes do registro">
                      <Button
                        onClick={() => registerDetails(record.id)}
                        shape="circle"
                        type="ghost"
                        icon={<UnorderedListOutlined />}
                      />
                    </Tooltip>
                  )}
                  {deleteEndpoint.length > 0 && (
                    <Tooltip placement="topLeft" title="excluir">
                      <Button
                        shape="circle"
                        type="ghost"
                        onClick={() => {
                          setCurrentRecord(record.id);
                          setConfirmModalIsVisible(true);
                        }}
                        icon={<DeleteOutlined style={{ color: "#ff0000" }} />}
                      />
                    </Tooltip>
                  )}
                  {downloadEndpoint?.length > 0 && (
                    <Tooltip placement="topLeft" title="baixar">
                      <Button
                        onClick={() => registerDownload(record.id)}
                        shape="circle"
                        type="ghost"
                        icon={<DownloadOutlined />}
                      />
                    </Tooltip>
                  )}
                </Space>
              ),
            });

            const data = response.map(data => {
              const newData = {};
              newData["key"] = data["id"]; //! api does not return id
              for (const col of columns) {
                newData[col["key"]] = data[col["key"]]; // !!!

                if (
                  typeof newData[col["key"]] === "object" &&
                  // !Array.isArray(newData[col["key"]]) &&
                  newData[col["key"]] !== null
                ) {
                  const currentObject = newData[col["key"]];
                  newData[col["key"]] = (
                    <Tooltip placement="topLeft" title={`abrir ${col["key"]}`}>
                      <Button
                        onClick={() => showObject(currentObject)}
                        shape="circle"
                        type="ghost"
                        icon={<UnorderedListOutlined />}
                      />
                    </Tooltip>
                  );
                }
              }

              return newData;
            });

            setListing({ columns, data });
          }
        })
        .catch(err => {
          message.error(err.message);
        })
        .finally(() => setLoadingListing(false));
    } else {
      setLoadingListing(false);
    }
  };

  const showObject = obj => {
    setIsDetailsModalVisible(true);
    setDetailsLoading(true);

    setDetailsData(obj);
    setDetailsLoading(false);
  };

  const registerDetails = id => {
    const retrieveOneEndpoint = service.available_endpoints.filter(
      x => x.request_type === "RETRIEVE_ONE",
    );

    if (retrieveOneEndpoint.length > 0) {
      setIsDetailsModalVisible(true);
      setDetailsLoading(true);
      const path = retrieveOneEndpoint[0].path.replace("{id}", id);
      callServiceEndpoint(service.root_url, path, retrieveOneEndpoint[0].request_method)
        .then(response => {
          setDetailsData(response);
        })
        .catch(err => {
          message.success(err.message);
        })
        .finally(() => {
          setDetailsLoading(false);
        });
    }
  };

  const registerDelete = id => {
    const deleteEndpoint = service.available_endpoints.filter(x => x.request_type === "DELETE_ONE");

    if (deleteEndpoint.length > 0) {
      const path = deleteEndpoint[0].path.replace("{id}", id);
      setLoadingListing(true);
      callServiceEndpoint(service.root_url, path, deleteEndpoint[0].request_method)
        .then(() => {
          const newData = [...listing["data"]];

          const indx = newData.map(d => d.id).indexOf(id);
          if (indx) {
            newData.splice(indx, 1);
            setListing({ columns: listing["columns"], data: newData });
          }
        })
        .catch(err => {
          message.error(err.message);
        })
        .finally(() => {
          setLoadingListing(false);
          setConfirmModalIsVisible(false);
        });
    }
  };

  const saveData = (() => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return (blob, fileName) => {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const registerDownload = id => {
    const downloadEndpoint = service.available_endpoints.filter(x => x.request_type === "DOWNLOAD");

    if (downloadEndpoint?.length > 0) {
      const path = downloadEndpoint[0].path.replace("{id}", id);
      setLoadingListing(true);
      callServiceEndpoint(service.root_url, path, downloadEndpoint[0].request_method, false)
        .then(response => {
          saveData(response, `${id}.txt`);
        })
        .catch(err => {
          message.success(err.message);
        })
        .finally(() => {
          setLoadingListing(false);
        });
    }
  };

  const externalLinksElement = useMemo(() => {
    if (service?.external_links) {
      return (
        <>
          <Typography.Text strong>Links externos</Typography.Text>
          <div className={styles.container_external_links}>
            {service.external_links.map(serv => (
              <a key={serv.label} href={serv.url} target="_blank" rel="nofollow noreferrer">
                <Button>{serv.label}</Button>
              </a>
            ))}
          </div>
          <Divider />
        </>
      );
    }
    return null;
  }, [service]);

  return (
    <>
      <Spin spinning={loading} className={styles.spinner} size="large" />
      {loading === false && (
        <PageLayout title={service.alias}>
          <span className={styles.go_back} onClick={() => navigate("/services")}>
            ◄ Voltar para serviços
          </span>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              key="1"
              tab={
                <span>
                  <FileOutlined />
                  Documentação
                </span>
              }
            >
              <Title level={5} className={styles.title_margin}>
                {service.description}
              </Title>
              <Divider />
              {externalLinksElement}
              {documentation}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="2"
              tab={
                <span>
                  <Html5Outlined />
                  Widgets
                </span>
              }
            >
              <Spin spinning={loadingWidgets} className={styles.spinner} size="large" />
              <div className={styles.spin_height}>{widgets}</div>
            </Tabs.TabPane>
            <Tabs.TabPane
              key="3"
              tab={
                <span onClick={() => (listing ? processListing() : null)}>
                  <OrderedListOutlined />
                  Dados
                </span>
              }
            >
              <Spin spinning={loadingListing} size="large" />
              {listing && (
                <Table
                  className={styles.table_margin}
                  dataSource={listing["data"]}
                  columns={listing["columns"]}
                  pagination={{ showSizeChanger: listing["data"].length > 10 }}
                />
              )}
              {!listing && loadingListing === false && <p>Não há dados para serem exibidos.</p>}
            </Tabs.TabPane>
          </Tabs>
          <JsonModal
            jsonData={detailsData}
            isLoading={detailsLoading}
            isVisible={isDetailsModalVisible}
            closeModal={() => setIsDetailsModalVisible(false)}
          />
          <ConfirmModal
            open={confirmModalIsVisible}
            title="Tem certeza que deseja excluir o registro?"
            message="A ação não poderá ser desfeita. Você tem certeza?"
            onClose={() => setConfirmModalIsVisible(false)}
            onConfirm={() => registerDelete(currentRecord)}
            type="delete"
          />
        </PageLayout>
      )}
    </>
  );
};

export { ServiceDetailsPage };
