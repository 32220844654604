/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import styles from "./PdfViewer.module.scss";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

export const PdfViewer = ({ blob }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <div className={styles.viewer}>
        <Viewer fileUrl={blob} plugins={[defaultLayoutPluginInstance]} defaultScale={0.8} />
      </div>
    </Worker>
  );
};
