/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/no-unresolved
// eslint-disable react/prop-types */
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { PageLayout } from "components/PageLayout";
import { useUserAccess } from "hooks";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { CopyBlock, dracula } from "react-code-blocks";
import styles from "./ModulesManual.module.scss";
import Markdown from "../../../utils/constants/ModuleDocs.md";

function ModulesManual() {
  const navigate = useNavigate();

  const { role } = useUserAccess();

  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    if (role !== "superadmin") {
      navigate("/404");
    }

    fetch(Markdown)
      .then(response => response.text())
      .then(text => {
        setMarkdown(text);
      });
  }, []);

  return (
    <PageLayout title="Documentação de módulos">
      <span className={styles.go_back} onClick={() => navigate("/settings")}>
        ◄ Voltar para módulos
      </span>
      <Space className={styles.d_flex} direction="vertical" size={32}>
        <Button
          type="primary"
          target="_blank"
          download
          icon={<DownloadOutlined />}
          href="/template_lis_module.py"
        >
          Download template
        </Button>
        <div className={styles.markdown_container}>
          {markdown.split("\n\n\n").map((text, index) => {
            if (text.indexOf("```") !== -1) {
              return (
                <CopyBlock
                  key={index}
                  text={text.replaceAll("```", "")}
                  language="python"
                  theme={dracula}
                />
              );
            }
            return <ReactMarkdown key={index} children={text} />;
          })}
        </div>
      </Space>
    </PageLayout>
  );
}

export { ModulesManual };
