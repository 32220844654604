const starterTemplate = `
<!doctype html>
<html lang="pt-br">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    <title>Título</title>
    <style>
      
      <!-- Cole o CSS aqui -->

    </style>
  </head>
  <body>

    <!-- Cole o código HTML aqui -->

    <!-- Cole o código JavaScript aqui -->

  </body>
</html>
`;

export {starterTemplate};