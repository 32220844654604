/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/no-unresolved
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Tabs, Typography } from "antd";
import { PageLayout } from "components/PageLayout";
import { useUserAccess } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ModalImage from "react-modal-image";
import styles from "./ServicesManual.module.scss";

function ServicesManual() {
  const navigate = useNavigate();
  const { role } = useUserAccess();

  useEffect(() => {
    if (role !== "superadmin") {
      navigate("/404");
    }
  }, []);

  return (
    <PageLayout title="Documentação de serviços">
      <span className={styles.go_back} onClick={() => navigate("/services")}>
        ◄ Voltar para serviços
      </span>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          key="1"
          tab={
            <span>
              <AppstoreAddOutlined />
              Criação
            </span>
          }
        >
          <Space direction="vertical" size={32}>
            <div>
              <Typography className={styles.title}>
                Introdução à criação de novos serviços
              </Typography>
              <div className={styles.container}>
                Este é um guia para a criação de novos serviços na LIS. Leia com atenção e siga as
                instruções indicadas, as seguintes seções apresentam, respectivamente: a criação do
                serviço, adição de um ou mais <em>widgets</em> e adição de um ou mais{" "}
                <em>endpoints</em>. <br />A criação do serviço <strong>sempre</strong>deve ser feita
                antes, pois será necessário seu ID para vincular os <em>widgets</em> e{" "}
                <em>endpoints</em> a ele.
              </div>
            </div>
            <div>
              <Typography className={styles.title}>Criação do serviço</Typography>
              <div className={styles.container}>
                Para a criação do serviço, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>
                </span>{" "}
                e utilizar o método <span style={{ color: "green" }}>POST</span> /registry para
                registrar um novo serviço. Nesse método, serão feitas as configurações gerais do
                serviço, cada um dos campos requeridos representam: <br />• <strong>name1:</strong>
                o nome do serviço que estará presente na tela inicial e no título da página do
                serviço; <br />• <strong>alias2:</strong> o nome único para o serviço, utilizado
                como seu ID; <br />• <strong>description3:</strong> descrição do serviço presente no
                início da aba &quot;Documentação&quot; dentro de cada serviço; <br />•{" "}
                <strong>logo_base644:</strong> o ícone do serviço presente na tela inicial,
                recomenda-se o uso de um arquivo vetorizado (svg); <br />•{" "}
                <strong>root_url:</strong> a URL para acessar o serviço (ex.:{" "}
                <em>https://facerecog.lis.sebrae-sc.com.br</em>);
                <br />• <strong>documentation_sufix:</strong> o sufixo da URL para acessar a
                documentação do serviço (ex.: <em>redoc</em>); <br />•{" "}
                <strong>external_links5:</strong> links externos contidos na documentação, para cada
                um, é necessário definir uma label e um link associado a essa. <br />
                Será retornado um ID do serviço, esse será usado como parâmetro para os próximos
                métodos.
                <div className={styles.image_container}>
                  <ModalImage
                    small="/print-1_small.png"
                    large="/print-1.png"
                    alt="Services image 1"
                  />
                  <ModalImage
                    small="/print-2_small.png"
                    large="/print-2.png"
                    alt="Services image 2"
                  />
                </div>
              </div>
            </div>
            <div>
              <Typography className={styles.title}>
                Criação dos <em>widgets</em>
              </Typography>
              <div className={styles.container}>
                Para a criação dos <em>widgets</em>, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>{" "}
                </span>{" "}
                e utilizar o método <span style={{ color: "green" }}>POST</span>{" "}
                {`/registry/{service_id}/widgets`} para registrar um <em>widget</em> e vinculá-lo a
                um serviço. Nesse método, serão adicionados os <em>widgets</em> do serviço, podem
                ser adicionados n <em>widgets</em> ao serviço, porém um por chamada do método, cada
                um dos campos requeridos representam: <br />• <strong>service_id:</strong> o ID do
                serviço que esse <em>widget</em> estará vinculado; <br />• <strong>title6:</strong>{" "}
                o título do <em>widget</em> que estará presente na aba de <em>widgets</em>; <br />•{" "}
                <strong>description7:</strong> a descrição sobre como utilizar o<em>widget</em>,
                presente após o título desse (pode-se utilizar <em>markdown</em>); <br />•{" "}
                <strong>widget8:</strong> um único arquivo HTML contendo o HTML, o CSS e o
                JavaScript, o <em>widget</em> estará presente após a descrição e após esse, estarão
                disponibilizados os códigos HTML, JavaScript e CSS.
                <div className={styles.image_container}>
                  <ModalImage
                    small="/print-3_small.png"
                    large="/print-3.png"
                    alt="Services image 3"
                  />
                  <ModalImage
                    small="/print-4_small.png"
                    large="/print-4.png"
                    alt="Services image 4"
                  />
                </div>
              </div>
            </div>
            <div>
              <Typography className={styles.title}>
                Criação dos <em>endpoints</em>
              </Typography>
              <div className={styles.container}>
                Para a criação dos <em>endpoints</em>, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>{" "}
                </span>
                e utilizar o método <span style={{ color: "green" }}>POST</span>{" "}
                {`/registry/{service_id}/`}
                <em>endpoints</em> para registrar um <em>endpoint</em> e vinculá-lo a um serviço.
                Nesse método, serão adicionados os <em>endpoints</em> do serviço, podem ser
                adicionados n <em>endpoints</em> ao serviço, porém um por chamada do método, cada um
                dos campos requeridos representam: <br />• <strong>service_id:</strong> o ID do
                serviço que esse <em>endpoint</em> estará vinculado; <br />•{" "}
                <strong>description:</strong> a descrição sobre como utilizar o <em>endpoint</em>;{" "}
                <br />• <strong>request_type:</strong> o tipo de solicitação, podendo ser:
                &quot;LISTING&quot;, &quot;RETRIEVE_ONE&quot;, &quot;DELETE_ONE&quot;,
                &quot;UPDATE&quot; ou &quot;DOWNLOAD&quot;; <br />• <strong>request_method:</strong>{" "}
                o método de solicitação, podendo ser: &quot;GET&quot;, &quot;POST&quot;,
                &quot;PUT&quot; ou &quot;DELETE&quot;; <br />• <strong>path:</strong> o caminho do{" "}
                <em>endpoint</em>, não coloque o root_url (ex.: <em>/facerecog/user</em>), para
                variáveis utilize o placeholder {} (ex.: <em>{`/facerecog/users/{id}`}</em>); <br />
                • <strong>query_parameters:</strong> quaisquer parâmetros de consulta usados pelo{" "}
                <em>endpoint</em> (campo opcional).
              </div>
            </div>
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span>
              <EditOutlined />
              Atualização
            </span>
          }
        >
          <Space direction="vertical" size={32}>
            <div>
              <Typography className={styles.title}>Introdução à atualização de serviços</Typography>
              <div className={styles.container}>
                Este é um guia para a atualização de serviços na LIS. Leia com atenção e siga as
                instruções indicadas, as seguintes seções apresentam: a busca do ID do documento e a
                atualização dos <em>widgets</em> de um serviço.
              </div>
            </div>
            <div>
              <Typography className={styles.title}>Busca pelo ID do documento</Typography>
              <div className={styles.container}>
                Para buscar o ID do serviço, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>{" "}
                </span>{" "}
                , utilize o método <span style={{ color: "#61AFFE" }}>GET</span> /registry para
                encontrar o ID serviço. Após, utilize o método{" "}
                <span style={{ color: "#61AFFE" }}>GET</span> {`/registry/{id}`} busque o campo
                &quot;document_id&quot;. O valor contido no campo &quot;document_id&quot; será
                utilizado para localizar o documento do serviço a ser atualizado.
              </div>
            </div>
            <div>
              <Typography className={styles.title}>Atualização do serviço</Typography>
              <div className={styles.container}>
                <Typography>
                  Para a atualização dos <em>widgets</em>, deve-se acessar o{" "}
                  <span>
                    <a
                      href="https://registry.lis.itera.com.br/registry/docs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Registro de serviço da LIS
                    </a>{" "}
                  </span>{" "}
                  e utilizar o método <span style={{ color: "#FCA130" }}>PUT</span>{" "}
                  {`/registry/{service_id}/`}
                  <em>widgets</em> para alterar um <em>widget</em> vinculado a um serviço. Nesse
                  método, deve ser subido um único arquivo HTML contendo o HTML, o CSS e o
                  JavaScript, o <em>widget</em> estará presente após a descrição e após esse,
                  estarão disponibilizados os códigos HTML, JavaScript e CSS.
                </Typography>
              </div>
            </div>
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="3"
          tab={
            <span>
              <DeleteOutlined />
              Exclusão
            </span>
          }
        >
          <Space direction="vertical" size={32}>
            <div>
              <Typography className={styles.title}>Introdução à exclusão de serviços</Typography>
              <div className={styles.container}>
                Este é um guia para a exclusão de serviços na LIS. Leia com atenção e siga as
                instruções indicadas, as seguintes seções apresentam: a busca do ID do serviço e a
                exclusão de um serviço.
              </div>
            </div>
            <div>
              <Typography className={styles.title}>Busca pelo ID do serviço</Typography>
              <div className={styles.container}>
                Para buscar o ID do serviço, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>{" "}
                </span>{" "}
                , utilize o método <span style={{ color: "#61AFFE" }}>GET</span> /registry para
                encontrar o ID serviço. O valor contido no campo &quot;id&quot; será utilizado para
                localizar o serviço a ser excluído.
              </div>
            </div>
            <div>
              <Typography className={styles.title}>Exclusão do serviço</Typography>
              <div className={styles.container}>
                Para a exclusão do serviço, deve-se acessar o{" "}
                <span>
                  <a
                    href="https://registry.lis.itera.com.br/registry/docs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Registro de serviço da LIS
                  </a>{" "}
                </span>{" "}
                e utilizar o método <span style={{ color: "red" }}>DELETE</span> {`/registry/{id}`}
                para excluir um serviço completo, incluindo os <em>endpoints</em> e <em>widgets</em>
                .
              </div>
            </div>
          </Space>
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  );
}

export { ServicesManual };
