import React from "react";

import { Spin, Button, Modal } from "antd";
import CodeEditor from "rmce";

// eslint-disable-next-line react/prop-types
function JsonModal({ jsonData, isVisible, isLoading, closeModal }) {
  const handleBack = () => {
    closeModal();
  };

  return (
    <Modal
      title="Detalhes do registro"
      visible={isVisible}
      onCancel={() => closeModal()}
      footer={[
        <Button key="back" onClick={() => handleBack()}>
          Fechar
        </Button>,
      ]}
      width="100%"
    >
      <Spin spinning={isLoading} />
      {isLoading === false && (
        <CodeEditor
          width="100%"
          height={500}
          className="rmce"
          value={JSON.stringify(jsonData, null, 2)}
          readOnly
        />
      )}
    </Modal>
  );
}

export { JsonModal };
