/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { useEffect, useState } from "react";
import { Modal, Button, Form, message, Input, Spin } from "antd";
import { createFolder, updateFolder, getAllFolders } from "api/Documents/documents.service";
import styles from "./FoldersModal.module.scss";

function FoldersModal({
  open,
  handleCloseModal,
  mountTable,
  initialValues = {},
  isEditingMode = false,
  id = undefined,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async data => {
    setLoading(true);
    if (isEditingMode) {
      await updateFolder(data.foldername, id)
        .then(() => {
          message.success("Pasta editada com sucesso!");
        })
        .catch(err => {
          message.error(err.message);
        })
        .finally(() => {
          refresh();
          setLoading(false);
        });
    } else {
      await createFolder(JSON.stringify(data))
        .then(() => {
          message.success("Pasta criada com sucesso!");
        })
        .catch(err => message.error(err.message))
        .finally(() => {
          refresh();
          setLoading(false);
        });
    }
    handleCloseModal();
  };

  // TODO: Verificar initialValue of Form antd
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
    if (!isEditingMode) {
      form.resetFields();
    }
  }, [initialValues, isEditingMode, form]);

  const refresh = () => {
    getAllFolders()
      .then(response => {
        mountTable(response);
      })
      .finally();
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title={isEditingMode ? "Editar pasta" : "Criar nova pasta"}
      visible={open}
      getContainer={false}
      forceRender
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={[
        <Button
          disabled={loading}
          type="primary"
          form="folder-creation-form"
          key="submit"
          htmlType="submit"
        >
          <Spin spinning={loading} size="small" />

          {isEditingMode ? "Salvar" : "Criar"}
        </Button>,
        <Button disabled={loading} key="back" onClick={handleCloseModal}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} name="folder-creation-form" onFinish={onFinish} layout="horizontal">
        <Form.Item
          name="foldername"
          label="Nome da Pasta"
          type="text"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={styles.custom_form_padding}
          rules={[
            {
              required: true,
              message: "Insira um nome para a pasta!",
            },
          ]}
        >
          <Input
            disabled={loading}
            placeholder="Nome da pasta"
            id="folder"
            name="folder"
            type="text"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export { FoldersModal };
