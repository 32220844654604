/* eslint-disable import/no-unresolved */
import React from "react";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
import { Tabs } from "antd";
import { Jobs } from "containers/Jobs";
import { CodeOutlined, ToolOutlined } from "@ant-design/icons";
import { Modules } from "containers/Modules";

function SettingsPage() {
  return (
    <PageLayout title="Configurações" subTitle="Configure os jobs dos microserviços da LIS.">
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane
          key="1"
          tab={
            <span>
              <ToolOutlined />
              Jobs
            </span>
          }
        >
          <Jobs />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span>
              <CodeOutlined />
              Módulos
            </span>
          }
        >
          <Modules />
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  );
}

export { SettingsPage };
