/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-fallthrough */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import { useState, useEffect } from "react";
import { Modal, Button, Form, message, Spin, Row, Col, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { getFileMetadata, updateFileMetadata, downloadFile } from "api/Documents/documents.service";
import { getTemplate } from "api/Templates";
import moment from "moment";
import { isIsoDate } from "utils/isIsoDate";
import styles from "./MetadataModal.module.scss";
import { DynamicForm } from "./DynamicForm/DynamicForm";
import { DocumentVisualization } from "./DocumentVisualization/DocumentVisualization";

const verifyDateType = objectValue => isIsoDate(objectValue.value);

function MetadataModal({ open, fileId, onClose }) {
  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [fileName, setFileName] = useState();
  const [templateFields, setTemplateFields] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    if (fileId) {
      setLoading(true);
      getFileMetadata(fileId)
        .then(response => {
          const metadata = response.document.metadata_doc;
          if (Object.keys(metadata).length > 0) {
            setInitialValues(metadata);
          } else {
            setInitialValues({});
          }
          setFileName(response.document.filename);
          getTemplate(response.template.id).then(response =>
            setTemplateFields(response.template_fields),
          );
          setLoading(false);
        })
        .catch(err => {
          message.error(err.message);
        })
        .finally(setLoading(false));
    }
  }, [fileId, open]);

  useEffect(() => {
    if (Object.keys(initialValues).length > 0) {
      // transforma propriedades do tipo data para o formato de entrada do DatePicker (moment())
      const parseInitialValues = Object.entries(initialValues).reduce((acc, [key, value]) => {
        if (verifyDateType(value)) {
          return { ...acc, [key]: { value: moment(value.value) } };
        }
        return { ...acc, [key]: { value: value.value } };
      }, {});
      form.setFieldsValue(parseInitialValues);
    } else {
      form.resetFields();
    }
  }, [form, initialValues]);

  const saveData = (() => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return (blob, fileName) => {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const registerDownload = () => {
    if (fileId) {
      downloadFile(fileId).then(res => {
        saveData(res, fileName);
      });
    }
  };

  const handleOk = () => {
    setInitialValues({});
    onClose();
  };

  const handleCancel = () => {
    setInitialValues({});
    onClose();
  };

  const onFinish = data => {
    setLoading(true);

    const newFieldsCleanObj = {};
    Object.keys(data).map((key, index) => {
      if (key === "newFields") {
        data[key] &&
          data[key].map(obj => {
            // eslint-disable-next-line dot-notation
            newFieldsCleanObj[obj["field"]] = obj["value"];
          });
      }
    });

    delete data.newFields;
    data = { ...data, ...newFieldsCleanObj };
    Object.keys(data).map((key, index) => {
      if (data[key] === "") {
        delete data[key];
      }
    });

    setIsCreating(true);
    updateFileMetadata(data, fileId)
      .then(() => {
        onClose();
        setInitialValues({});
        setLoading(false);
        message.success("Metadados atualizados!");
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      getContainer={false}
      forceRender
      title="Edição de metadados"
      visible={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width="98%"
      footer={[
        <Button
          disabled={isCreating}
          type="primary"
          form="metada-edition-form"
          key="submit"
          htmlType="submit"
        >
          <Spin spinning={loading} size="small" />
          Salvar
        </Button>,
        <Button
          disabled={isCreating}
          key="back"
          onClick={() => {
            setInitialValues({});
            onClose();
          }}
        >
          Cancelar
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="metada-edition-form"
        onFinish={onFinish}
        layout="horizontal"
        initialValues={initialValues}
      >
        {fileName}
        <Tooltip placement="topLeft" title="baixar">
          <Button
            title="Download do arquivo"
            className={styles.plus_button}
            onClick={() => registerDownload()}
            type="ghost"
            icon={<DownloadOutlined />}
          />
        </Tooltip>
        <Row gutter={16} className={styles.space_align_document}>
          <Col span={12} style={{ padding: 0 }}>
            <DocumentVisualization fileId={fileId} fileName={fileName} />
          </Col>
          <Col span={12}>
            <DynamicForm templateFields={templateFields} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export { MetadataModal };
