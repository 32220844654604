// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

const urlBase = window._env_.REACT_APP_METADATA_URL || process.env.REACT_APP_METADATA_URL;

async function createTemplate(data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${urlBase}/templates`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllTemplates() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/templates`, requestOptions);
  return await middlewareDefault(response);
}

async function getTemplate(templateId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/templates/${templateId}`, requestOptions);
  return await middlewareDefault(response);
}

async function updateTemplate(data, templateId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${urlBase}/templates/${templateId}`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteTemplate(templateId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/templates/${templateId}`, requestOptions);
  return await middlewareDefault(response);
}

export { createTemplate, getAllTemplates, getTemplate, updateTemplate, deleteTemplate };
