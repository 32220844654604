/* eslint-disable consistent-return */
/* eslint-disable default-case */
import { useReducer } from "react";

/**
 * Custom hook to update cron value and input value.
 *
 * Cannot use InputRef to update the value because of a change in antd 4.19.0.
 *
 * @param defaultValue - The default value of the input and cron component.
 * @returns - The cron and input values with the dispatch function.
 */
export function useCronReducer(defaultValue) {
  const [values, dispatchValues] = useReducer(
    (prevValues, action) => {
      switch (action.type) {
        case "SET_CRON_VALUE":
          return {
            inputValue: prevValues.inputValue,
            cronValue: action.value,
          };
        case "SET_INPUT_VALUE":
          return {
            inputValue: action.value,
            cronValue: prevValues.cronValue,
          };
        case "SET_VALUES":
          return {
            inputValue: action.value,
            cronValue: action.value,
          };
      }
    },
    {
      inputValue: defaultValue,
      cronValue: defaultValue,
    },
  );

  return [values, dispatchValues];
}
