// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

const urlBase = window._env_.REACT_APP_METADATA_URL || process.env.REACT_APP_METADATA_URL;

async function createFolder(data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(`${urlBase}/folder`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllFolders() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/folder`, requestOptions);
  return await middlewareDefault(response);
}

async function updateFolder(name, folderId) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  };
  const response = await fetch(`${urlBase}/folder/${folderId}?folder_name=${name}`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteFolder(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/folder/${id}`, requestOptions);
  return await middlewareDefault(response);
}

// eslint-disable-next-line no-unused-vars
async function uploadFiles(folderId, loggedUser, file, lastModified, templateId) {
  const token = window.localStorage.getItem("token");
  const formData = new FormData();
  formData.append("folder_id", folderId);
  formData.append("template_id", templateId);
  formData.append("file", file);
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
    },
    body: formData,
  };

  const response = await fetch(`${urlBase}/files`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllFilesFromFolder(folderId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/folder/${folderId}`, requestOptions);
  return await middlewareDefault(response);
}

async function getFileMetadata(fileId) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/files/${fileId}`, requestOptions);
  return await middlewareDefault(response);
}

async function updateFileMetadata(values, fileId) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ metadata_doc: values }),
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
  };
  const response = await fetch(`${urlBase}/files/${fileId}`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteFile(fileId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(`${urlBase}/files/${fileId}`, requestOptions);
  return await middlewareDefault(response);
}

async function downloadFile(fileId) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${urlBase}/files/${fileId}/download`, requestOptions);

  // if (!response.ok) {
  //   if (response.status === 401 || response.status === 403) {
  //     window.localStorage.removeItem("token");
  //     window.location = "/login";
  //   } else {
  //     throw new Error(response.statusText);
  //   }
  // }
  // return await response.blob();
  // return await response;
  const test = await middlewareDefault(response);
  return test;
}

export {
  createFolder,
  getAllFolders,
  updateFolder,
  deleteFolder,
  uploadFiles,
  getAllFilesFromFolder,
  getFileMetadata,
  updateFileMetadata,
  deleteFile,
  downloadFile,
};
