/* eslint-disable import/no-unresolved */
import {
  LoginPage,
  ServicesPage,
  ServiceDetailsPage,
  Users,
  Tokens,
  ForgotPasswordPage,
} from "containers";
import { NotFound } from "containers/NotFound";
import { About } from "containers/About";
import { Changelog } from "containers/Changelog";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Metadata } from "containers/Metadata/Metadata";
import { SettingsPage } from "containers/Settings";
import { Manual } from "containers/Manual";
import { ServicesManual } from "containers/Manual/ServicesManual";
import { ModulesManual } from "containers/Manual/ModulesManual";
import { PrivateRoute } from "./PrivateRoute";
import { PrivateRouteUser } from "./PrivateRouteUser";

function LisRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/services" element={<ServicesPage />} />
          <Route exact path="/services/:id" element={<ServiceDetailsPage />} />
          <Route exact path="/tokens" element={<Tokens />} />
          <Route exact path="/settings" element={<SettingsPage />} />
          <Route element={<PrivateRouteUser />}>
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/metadata" element={<Metadata />} />
            <Route exact path="/settings" element={<SettingsPage />} />
            <Route path="/manual" element={<Manual />} />
            <Route path="/manual/services" element={<ServicesManual />} />
            <Route path="/manual/modules" element={<ModulesManual />} />
          </Route>
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export { LisRoutes };
