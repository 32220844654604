/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, message, Select } from "antd";
import { MultipleFileUploadField } from "containers/Documents/MultipleFileUploadField";
import moment from "moment";
import { uploadFiles } from "api/Documents";
import { getAllTemplates } from "api/Templates";

function DocumentsModal({ refreshTable, folderId }) {
  const [openModal, setOpenModal] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false);

  // Templates
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const [templateId, setTemplateId] = useState(null);

  // Files
  const [files, setFiles] = useState([]);

  const loggedUser = JSON.parse(window.localStorage.getItem("userData"));

  useEffect(() => {
    getAllTemplates().then(res => setSelectedTemplates(res));
  }, [openModal]);

  useEffect(() => {
    if (folderId === undefined) {
      setFolderSelected(true);
    } else {
      setFolderSelected(false);
    }
  }, [folderId]);

  const handleCloseModal = () => {
    setFiles([]);
    refreshTable();
    setTemplateId(null);
    setOpenModal(false);
  };

  const templateOptions = useMemo(
    () =>
      selectedTemplates.map(template => ({
        label: template.name,
        value: template.id,
      })),
    [selectedTemplates],
  );

  const handleTemplateChange = value => {
    setTemplateId(value);
  };

  const handleUpdateFiles = () => {
    const isValid = validateUpload();
    if (isValid) {
      const lastModified = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
      files.forEach(async file => {
        await uploadFiles(folderId, loggedUser, file, lastModified, templateId)
          .catch(() => {
            message.error("Erro interno no servidor. Contate o administrador do sistema.");
          })
          .finally(() => {
            message.success("Documento importado com sucesso!");
            refreshTable();
          });
      });
      handleCloseModal();
    }
  };

  const validateUpload = () => {
    if (files.length === 0) {
      message.error("Selecione um ou mais arquivos para upload");
      return false;
    }
    if (templateId === null) {
      message.error("Selecione um template para o documento");
      return false;
    }
    return true;
  };

  const handleUpdateAcceptFiles = newFiles => {
    setFiles(prevState => [...prevState, ...newFiles]);
  };

  const onDelete = file => {
    setFiles(acceptedFiles => acceptedFiles.filter(fw => fw.name !== file.name));
  };

  return (
    <>
      {folderId != null && (
        <Button disabled={folderSelected} type="primary" onClick={() => setOpenModal(true)}>
          Subir arquivos
        </Button>
      )}
      <Modal
        title="Upload de arquivos"
        centered
        visible={openModal}
        onCancel={handleCloseModal}
        width={700}
        footer={[
          <div
            key="select"
            style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}
          >
            <div>
              {templateOptions.length > 0 && (
                <div>
                  <p style={{ marginBottom: 1, textAlign: "left" }}>Selecione um template:</p>
                  <Select
                    style={{ width: 400, textAlign: "left" }}
                    onChange={handleTemplateChange}
                    value={templateId}
                    defaultValue={templateId}
                    placeholder="Selecione um template"
                  >
                    {templateOptions.map(template => (
                      <Select.Option key={template.value} value={template.value}>
                        {template.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            <div>
              <Button key="confirm" type="primary" onClick={handleUpdateFiles}>
                Importar
              </Button>
              <Button key="back" type="default" onClick={handleCloseModal}>
                Fechar
              </Button>
            </div>
          </div>,
        ]}
      >
        <MultipleFileUploadField
          acceptFiles={files}
          onDelete={onDelete}
          handleUpdateAcceptFiles={handleUpdateAcceptFiles}
        />
      </Modal>
    </>
  );
}
export { DocumentsModal };
