import { Button } from "antd";
// eslint-disable-next-line import/no-unresolved
import { useUserToken } from "hooks";
import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.center_layout}>
      <div className={styles.container}>
        <div className={styles.content_center}>
          <img
            src="./page-not-found.svg"
            alt="página não encontrada"
            title="página não encontrada"
          />
          <h2>Página não encontrada!</h2>
          {useUserToken() === null ? (
            <Button type="primary" href="/login">
              {" "}
              Voltar para login
            </Button>
          ) : (
            <Button type="primary" href="/services">
              {" "}
              Voltar para serviços
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export { NotFound };
