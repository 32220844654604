/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-relative-packages */
// eslint-disable-next-line import/no-unresolved
import React, { useRef, useState, useEffect } from "react";
import { DashOutlined, DownloadOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Input, message, Space, Spin, Table } from "antd";
import moment from "moment/moment";
import { toString } from "cronstrue";
import "../../../node_modules/cronstrue/locales/pt_BR";
// eslint-disable-next-line import/no-unresolved
import { jobMock } from "utils/mock/jobMock";
import { DeleteWithPasswordModal } from "components/DeleteModal/DeleteWithPasswordModal";
import {
  addModulesToJob,
  createJob,
  deleteJob,
  getAllJobs,
  getAllModules,
  updateJob,
} from "api/Etl";
import styles from "./Jobs.module.scss";
import { JobsDetailsModal } from "./JobsDetailsModal";
import { JobsModal } from "./JobsModal";
import { LogsModal } from "./LogsModal";
import { MenuJobActions } from "./MenuActions";
import { getColumnSearchProps } from "../../components/ColumnSearch";

const statusTypes = {
  paused: "Pausado",
  running: "Executando",
  stopped: "Parado",
  error: "Erro",
};

const badgeTypes = {
  paused: "warning",
  running: "success",
  stopped: "error",
  error: "error",
};

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  // Modal
  const [openJobModal, setOpenJobModal] = useState(false);
  const [openJobDetailModal, setOpenJobDetailModal] = useState(false);
  const [openLogsModal, setOpenLogsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedJob, setSelectedJob] = useState(null);

  const { Search } = Input;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const fetchJobs = () => {
    setLoading(true);
    getAllJobs().then(response => {
      setJobs(normalizeJobs(response));
      setLoading(false);
    });
  };

  const fetchModules = () => {
    setLoading(true);
    getAllModules().then(response => {
      setModules(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchJobs();
    fetchModules();
  }, []);

  const normalizeJobs = arr => arr.map(it => ({ ...it, key: `${it.id}` }));

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("");
  };

  const handleUpdateJob = (formValues, cronValue) => {
    setLoading(true);
    const data = {
      alias: formValues.alias,
      description: formValues.description,
      cron_expression: cronValue,
    };
    updateJob(selectedJob.id, data)
      .then(resp => {
        addModulesToJob(selectedJob.id, formValues.modules)
          .then(() => {
            message.success("Job atualizado com sucesso!");
            setOpenJobModal(false);
            fetchJobs();
            setLoading(false);
          })
          .catch(() => {
            message.error("Erro ao atualizar job!");
          });
      })
      .catch(() => {
        message.error("Erro ao atualizar job!");
      });
  };

  const handleCreateJob = (formValues, cronValue) => {
    setLoading(true);
    const data = {
      alias: formValues.alias,
      description: formValues.description,
      cron_expression: cronValue,
    };
    createJob(data)
      .then(resp => {
        addModulesToJob(resp.id, formValues.modules)
          .then(() => {
            message.success("Job criado com sucesso!");
            setOpenJobModal(false);
            fetchJobs();
            setLoading(false);
          })
          .catch(() => {
            message.error("Erro ao criar job!");
          });
      })
      .catch(() => {
        message.error("Erro ao criar job!");
      });
  };

  const columns = [
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      ...getColumnSearchProps(
        "alias",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput,
        handleSearch,
        handleReset,
      ),
      sorter: {
        compare: (a, b) => a.alias.localeCompare(b.alias),
      },
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      width: "18%",
      render: text => (
        <div className={styles.w_max_250}>
          <div className={`${styles.text_overflow}`}>{text}</div>
        </div>
      ),
      ...getColumnSearchProps(
        "description",
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn,
        searchInput,
        handleSearch,
        handleReset,
      ),
    },
    {
      title: "Frequência",
      dataIndex: "cron_expression",
      key: "cron_expression",
      // render: text => toString(text, { locale: "pt_BR" }),
      render: text => text,
    },
    // {
    //   title: "Módulos",
    //   dataIndex: "modules",
    //   key: "modules",
    //   width: "7%",
    //   // render: modules => modules.length,
    //   render: 4,
    // },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: "Executando",
          value: "running",
        },
        {
          text: "Pausado",
          value: "paused",
        },
      ],
      key: "status",
      width: "12%",
      render: status => (
        <span>
          <Badge status={badgeTypes[status]} text={statusTypes[status]} />
        </span>
      ),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    // {
    //   title: "Última execução",
    //   dataIndex: "lastExecution",
    //   key: "lastExecution",
    //   width: "14%",
    //   render: lastExecution => moment(lastExecution).format("DD/MM/YYYY HH:mm"),
    //   sorter: {
    //     compare: (a, b) => {
    //       const [aDay, aMonth, aYear] = moment(a.lastExecution).format("DD/MM/YYYY").split("/");
    //       const [bDay, bMonth, bYear] = moment(b.lastExecution).format("DD/MM/YYYY").split("/");
    //       return moment(new Date(bYear, bMonth, bDay)).diff(new Date(aYear, aMonth, aDay));
    //     },
    //   },
    // },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            type="ghost"
            icon={<UnorderedListOutlined />}
            onClick={() => {
              fetchModules();
              setSelectedJob(record);
              setOpenJobDetailModal(true);
            }}
          />
          <Button
            type="ghost"
            shape="circle"
            icon={<DownloadOutlined />}
            onClick={() => {
              setSelectedJob(record);
              setOpenLogsModal(true);
            }}
          />
          <Dropdown
            overlay={
              <MenuJobActions
                job={record}
                handleDelete={handleOpenDeleteModal}
                handleEdit={handleEditJob}
                handlePause={handlePauseJob}
                handleReset={handleResetJob}
              />
            }
            trigger={["click"]}
            placement="topRight"
          >
            <Button type="ghost" shape="circle" icon={<DashOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  // handles
  const handleCloseJobModal = () => {
    setOpenJobModal(false);
  };

  const handleOpenJobModal = () => {
    fetchModules();
    setSelectedJob(null);
    setOpenJobModal(true);
  };

  const handleCloseJobDetailModal = () => {
    setSelectedJob(null);
    setOpenJobDetailModal(false);
  };

  // const handleOpenLogsModal = () => {
  //   setOpenLogsModal(true);
  // };

  const handleCloseLogsModal = () => {
    setOpenLogsModal(false);
  };

  //   const handleOpenJobDetailModal = () => {
  //    setOpenJobDetailModal(true);
  //   };

  const handleOpenDeleteModal = job => {
    setSelectedJob(job);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleEditJob = job => {
    setSelectedJob(job);
    setOpenJobModal(true);
  };

  const handlePauseJob = job => {
    console.log(job);
  };

  const handleResetJob = job => {
    console.log(job);
  };

  const handleDeleteJob = id => {
    deleteJob(id)
      .then(() => {
        setOpenDeleteModal(false);
        message.success("Job deletado com sucesso!");
        fetchJobs();
      })
      .catch(() => {
        message.error("Erro ao deletar job!");
        setOpenDeleteModal(false);
      });
  };

  return (
    <>
      <div className={styles.container_align_right}>
        <Button type="primary" onClick={handleOpenJobModal}>
          Criar job
        </Button>
      </div>
      <Spin className={styles.services_spinner} spinning={loading} size="large" />
      {!loading && (
        <div className={styles.settings_listing}>
          {columns && jobs.length > 0 && (
            <Table className={styles.table} columns={columns} dataSource={jobs} />
          )}
        </div>
      )}
      <JobsModal
        open={openJobModal}
        onClose={handleCloseJobModal}
        job={selectedJob}
        loading={false}
        onConfirm={selectedJob ? handleUpdateJob : handleCreateJob}
      />
      <JobsDetailsModal
        open={openJobDetailModal}
        onClose={handleCloseJobDetailModal}
        job={selectedJob}
        selectedJob={32}
        modules={modules}
      />
      <LogsModal open={openLogsModal} onClose={handleCloseLogsModal} job={selectedJob} />
      <DeleteWithPasswordModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        isLoading={false}
        onConfirm={() => handleDeleteJob(selectedJob?.id)}
        title="Deletar Job"
        item={selectedJob}
        password={selectedJob?.alias}
      />
    </>
  );
}

export { Jobs };
