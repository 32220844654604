/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-unresolved */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, message, Space, Spin, Table } from "antd";
import {
  getAllTemplates,
  createTemplate,
  deleteTemplate,
  getTemplate,
  updateTemplate,
} from "api/Templates";
import { ConfirmModal } from "components/ConfirmModal";
import { useEffect, useMemo, useState } from "react";
import { TemplateModal } from "./TemplateModal/TemplateModal";
import styles from "./Templates.module.scss";

function Templates() {
  const [templatesData, setTemplatesData] = useState([]);
  const [template, setTemplate] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllTemplates()
      .then(res => setTemplatesData(res))
      .catch(err => message.error(err.message))
      .finally(setLoading(false));
  }, []);

  const refreshTemplates = async () => {
    setLoading(true);
    await getAllTemplates()
      .then(res => {
        setTemplatesData(res);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const templateDataSource = useMemo(
    () => templatesData.map(temp => ({ key: `${temp.id}`, name: temp.name })),
    [templatesData],
  );

  const handleCreateTemplate = async data => {
    setLoading(true);
    await createTemplate(data)
      .then(() => {
        message.success("Template criado com sucesso!");
        handleCloseModal();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refreshTemplates();
        handleCloseModal();
        setLoading(false);
      });
  };

  const handleDeleteTemplate = async templateId => {
    setLoading(true);
    await deleteTemplate(templateId)
      .then(() => {
        message.success("Template removido com sucesso!");
        handleCloseConfirmModal();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        handleCloseConfirmModal();
        refreshTemplates();
        setLoading(false);
      });
  };

  const handleUpdateTemplate = async (data, templateId) => {
    setLoading(true);
    await updateTemplate(data, templateId)
      .then(() => {
        message.success("Template atualizado!");
        handleCloseModal();
      })
      .catch(err => message.error(err.message))
      .finally(() => {
        handleCloseModal();
        refreshTemplates();
        setLoading(false);
      });
  };

  const columnsTemplate = [
    {
      title: "Nome do template",
      dataIndex: "name",
      key: "name",
      width: "90%",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.state),
      },
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            type="ghost"
            icon={<EditOutlined alt="Editar template" title="Editar template" />}
            onClick={async () => {
              setSelectedTemplate(record);
              await changeSelectedTemplate(record.key);
              setOpenModal(true);
            }}
          />
          <Button
            shape="circle"
            type="ghost"
            icon={
              <DeleteOutlined
                className={styles.color_red}
                alt="Deletar template"
                title="Deletar template"
              />
            }
            onClick={() => {
              setSelectedTemplate(record);
              handleOpenModalConfirm();
            }}
          />
        </Space>
      ),
    },
  ];

  const handleCloseModal = () => setOpenModal(false);

  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const handleOpenModalConfirm = () => setOpenConfirmModal(true);

  const changeSelectedTemplate = async templateId => {
    setLoading(true);
    await getTemplate(templateId)
      .then(res => setTemplate(res))
      .catch(err => message.error(err.message))
      .finally(setLoading(false));
  };

  const initialValues = useMemo(
    () => (template != null ? { fields: template.template_fields } : []),
    [template],
  );

  const initialTemplateName = useMemo(
    () => (template != null ? { name: template.name } : {}),
    [template],
  );

  const isEditMode = useMemo(() => template != null, [template]);

  return (
    <>
      <div className={styles.mr_40}>
        <div>
          <div className={styles.templates_button}>
            <Button
              type="primary"
              onClick={() => {
                setTemplate(undefined);
                setOpenModal(true);
              }}
            >
              Criar template
            </Button>
          </div>
          <div>
            <Spin className={styles.services_spinner} spinning={loading} size="large" />
            {columnsTemplate && (
              <Table
                style={{
                  width: "100%",
                  marginRight: 10,
                }}
                dataSource={templateDataSource}
                columns={columnsTemplate}
                loading={loading}
                pagination={{ showSizeChanger: templateDataSource.length > 10 }}
              />
            )}
          </div>
        </div>
      </div>
      <TemplateModal
        onClose={handleCloseModal}
        visible={openModal}
        loading={loading}
        isEditMode={isEditMode}
        initialValues={initialValues}
        initialTemplateName={initialTemplateName}
        selectedTemplate={template}
        onConfirm={isEditMode ? handleUpdateTemplate : handleCreateTemplate}
      />
      <ConfirmModal
        title={
          selectedTemplate != null
            ? `Você está prestes a remover o template "${selectedTemplate.name}".`
            : ""
        }
        message="A ação não poderá ser desfeita. Você tem certeza?"
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        isLoading={loading}
        type="delete"
        onConfirm={() => handleDeleteTemplate(selectedTemplate.key)}
      />
    </>
  );
}

export { Templates };
