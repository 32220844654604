/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Modal, Row, Spin } from "antd";
import { MultipleFileUploadField } from "containers/Documents/MultipleFileUploadField";
import React, { useState, useEffect } from "react";
import CodeEditor from "rmce";
import Prism from "prismjs";
import { getModuleFile } from "api/Etl";
import "prismjs/components/prism-java";
import "prismjs/components/prism-python";
import styles from "./CreateModulesModal.module.scss";

const getCodeStringByFile = async file => {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise(resolve => {
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

function CreateModulesModal({ open, onClose, onConfirm, loading, module = undefined }) {
  const [codeFile, setCodeFile] = useState([]);
  const [codeElements, setCodeElements] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    Prism.highlightAll();
    setFileLoading(false);
    if (module && codeFile.length === 0) {
      form.setFieldsValue({
        alias: module.alias,
        description: module.description,
      });
      fetchFile();
    }
    if (codeFile.length > 0) {
      getCodeElements();
    } else {
      setCodeElements(null);
    }
  }, [module, codeFile]);

  const fetchFile = () => {
    setFileLoading(true);
    getModuleFile(module.key).then(async data => {
      const file = new File([data], "main.py");
      setCodeFile([file]);
      getCodeElements();
      setFileLoading(false);
    });
  };

  const handleUpdateAcceptCodeFiles = newFiles => {
    setCodeFile(prevState => [...prevState, ...newFiles]);
  };

  const getCodeElements = async () => {
    if (codeFile.length > 0 || codeFile.size > 0) {
      const code = await getCodeStringByFile(codeFile[0]);
      const ext = codeFile[0].name.split(".").pop();
      setCodeElements({
        name: codeFile[0].name,
        code,
        ext,
      });
    }
  };

  const validateUpload = () => {
    if (codeFile.length === 0) {
      message.error("Selecione um arquivo .py para upload");
      return false;
    }
    return true;
  };

  const handleCloseModal = () => {
    form.resetFields();
    setCodeFile([]);
    onClose();
  };

  const onDeleteCodeFile = () => setCodeFile([]);

  const handleConfirm = async values => {
    if (module != null) {
      await onConfirm({ ...values });
    } else {
      const isValid = validateUpload();
      if (isValid) {
        await onConfirm(values, codeFile[0]);
        form.resetFields();
      }
    }
  };

  return (
    <Modal
      title={module != null ? "Editar Módulo" : "Criar Módulo"}
      visible={open}
      onCancel={handleCloseModal}
      getContainer={false}
      forceRender
      width={750}
      footer={[
        <Button
          type="primary"
          key="submit"
          form="module-creation-form"
          htmlType="submit"
          disabled={loading}
        >
          {module != null ? "Editar" : "Criar"}
        </Button>,
        <Button key="back" onClick={handleCloseModal} disabled={loading}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} layout="horizontal" name="module-creation-form" onFinish={handleConfirm}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="alias"
              label="Alias"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              tooltip="Nome que remeta ao módulo que será exibido no sistema."
              rules={[{ required: true, message: "Insira o alias do módulo" }]}
            >
              <Input placeholder="Alias" id="alias" name="alias" type="text" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Descrição"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              tooltip="Descrição que remeta ao módulo que será exibido no sistema."
              rules={[{ required: true, message: "Insira a descrição do módulo" }]}
            >
              <Input.TextArea
                placeholder="Descrição"
                id="description"
                name="description"
                type="text"
                rows={6}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row> */}
        {fileLoading && (
          <Spin className={styles.services_spinner} spinning={fileLoading} size="large" />
        )}
        {codeFile.length === 0 && !module && (
          <>
            <div className={styles.link_manual}>
              <Button type="link" href="/manual/modules">
                <QuestionCircleOutlined /> Não possui um arquivo? Visite a documentação aqui
              </Button>
            </div>
            <h4>Upload arquivo .py</h4>
            <MultipleFileUploadField
              acceptFiles={codeFile}
              onDelete={() => {}}
              maxFiles={1}
              extensions={[".py"]} // adicionar extensões
              handleUpdateAcceptFiles={handleUpdateAcceptCodeFiles}
              showList={false}
            />
          </>
        )}
        {codeFile.length > 0 && codeElements != null && (
          <div>
            <div className={styles.align_row}>
              <p>{codeElements.name}</p>
              {module === null && (
                <Button type="ghost" shape="circle" onClick={onDeleteCodeFile}>
                  <DeleteOutlined style={{ color: "#ff0000" }} />
                </Button>
              )}
            </div>
            <CodeEditor
              value={codeElements.code}
              highlight={code =>
                Prism.highlight(code, Prism.languages[codeElements.ext], codeElements.ext)
              }
              style={{ height: 300 }}
              width="100%"
              className="rmce"
              readOnly
            />
          </div>
        )}
      </Form>
    </Modal>
  );
}

export { CreateModulesModal };
