const useUserToken = () => {
  // const userToken = window.localStorage.getItem(user);
  const userToken = window.localStorage.getItem("token");
  return userToken;
};

const useUserData = userdata => {
  const userData = window.localStorage.getItem(userdata);

  return userData;
};

export { useUserToken, useUserData };
