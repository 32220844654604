const middlewareDefault = async response => {
  if (response.status === 401 || response.status === 403) {
    window.localStorage.removeItem("token");
    window.location = "/login";
  }
  // 415, 422 e 500 "Erro interno no servidor. Contate o administrador do sistema." msn inside catch
  if (response.status === 415 || response.status === 422 || response.status === 500) {
    throw new Error("Erro interno no servidor. Contate o administrador do sistema.");
  }
  if (response.status >= 400 && response.status < 500) {
    const respJson = await response.json();
    throw new Error(respJson.detail);
  }
  const contentType = response.headers.get("Content-Type");
  let resp;
  if (contentType === "application/json") {
    resp = await response.json();
  }
  if (contentType !== "application/json") {
    resp = await response.blob();
  }
  return resp;
};
export { middlewareDefault };
