/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Avatar, Button, Input, Modal, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "./DeleteWithPasswordModal.module.scss";

// eslint-disable-next-line react/prop-types
function DeleteWithPasswordModal({ open, onClose, onConfirm, isLoading, item, password }) {
  const [isValidate, setIsValidate] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (item && password) {
      if (password === inputValue) {
        setIsValidate(false);
      } else {
        setIsValidate(true);
      }
    }
  }, [open, item, inputValue]);

  const handleClose = () => {
    setInputValue("");
    onClose();
  };

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      confirmLoading={isLoading}
      footer={[
        <Button key="confirm" type="primary" danger onClick={onConfirm} disabled={isValidate}>
          Remover
        </Button>,
        <Button key="back" onClick={handleClose}>
          Fechar
        </Button>,
      ]}
    >
      <div className={styles.positioner}>
        <div className={styles.avatar_container}>
          <div>
            <Avatar size={75} className={styles.avatar}>
              <DeleteOutlined className={styles.icon_size} />
            </Avatar>
          </div>
        </div>
        <Typography.Paragraph className={styles.message}>
          Você está prestes a remover &nbsp;
          <Typography.Text>{password || ""}</Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph className={styles.text_center}>
          A ação não poderá ser desfeita.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Digite{" "}
          <Typography.Text className={styles.password_text}>{password || ""}</Typography.Text> para
          confirmar.
        </Typography.Paragraph>
        <Input
          name="input"
          value={inputValue}
          autoComplete="off"
          onChange={e => setInputValue(e.target.value)}
        />
      </div>
    </Modal>
  );
}

export { DeleteWithPasswordModal };
