/* eslint-disable react/prop-types */
import { Button, Modal } from "antd";
import React from "react";

function ModulesDetailsModal({ open, onClose, module }) {
  return (
    <Modal
      title="Detalhes do múdulo"
      visible={open}
      onCancel={onClose}
      getContainer={false}
      forceRender
      footer={<Button onClick={onClose}>Fechar</Button>}
    >
      <p>Alias: {module?.alias}</p>
      <p>Descrição: {module?.description}</p>
    </Modal>
  );
}

export { ModulesDetailsModal };
