/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  FolderOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import {
  getAllFolders,
  deleteFolder,
  deleteFile,
  getAllFilesFromFolder,
} from "api/Documents/documents.service";
import { message, Spin, Table, Button, Space, Tooltip } from "antd";

import { DocumentsModal } from "containers/Documents/DocumentsModal";
import { MetadataModal } from "containers/Documents/MetadataModal";
import { FoldersModal } from "containers/Documents/FoldersModal";
import { ConfirmModal } from "components/ConfirmModal";
import styles from "./Documents.module.scss";

function Documents() {
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openConfirmFolderModal, setOpenConfirmFolderModal] = useState(false);
  const [openConfirmFileModal, setOpenConfirmFileModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [initialValue, setInitialValue] = useState({});
  const [loading, setLoading] = useState(true);
  const [folderData, setFolderData] = useState();
  const [fileData, setFileData] = useState();
  const [currentFolderId, setCurrentFolderId] = useState();
  const [currentMetadataFileId, setCurrentMetadataFileId] = useState();
  const [openMetadataModal, setOpenMetadataModal] = useState(false);

  const filesByFolderId = folderId => {
    getFilesByFolderId(folderId);
    setCurrentFolderId(folderId);
  };

  //* Folders
  const folderDeletion = id => {
    setLoading(true);
    deleteFolder(id)
      .then(() => {
        const newData = [...folderData];
        const indx = newData.map(d => d.id).indexOf(id);
        if (indx) {
          newData.splice(indx, 1);
          setFolderData(newData);
        }
        message.success("Pasta removida com sucesso!");
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refresh();
        setLoading(false);
      });
  };

  const columnsFolder = [
    {
      title: "",
      dataIndex: "folderButton",
      key: "folderButton",
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="circle"
            type="ghost"
            onClick={() => filesByFolderId(record.folder_id)}
            icon={
              currentFolderId === record.folder_id ? <FolderOpenOutlined /> : <FolderOutlined />
            }
          />
        </Space>
      ),
    },
    {
      title: "Pastas",
      dataIndex: "foldername",
      key: "foldername",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="topLeft" title="editar">
            <Button
              shape="circle"
              type="ghost"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedFolder(record);
                setEditMode(true);
                setInitialValue({
                  ...initialValue,
                  foldername: record.foldername,
                });
                setOpenFolderModal(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="excluir">
            <Button
              shape="circle"
              type="ghost"
              disabled={(record?.files ?? 0) > 0}
              icon={<DeleteOutlined style={{ color: record?.files ? "#ccc" : "#ff0000" }} />}
              onClick={() => {
                setSelectedFolder(record);
                handleOpenFolderModalConfirm();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  //* Files
  const getFilesByFolderId = folderId => {
    setLoading(true);
    getAllFilesFromFolder(folderId)
      .then(res => {
        mountFilesTable(res.files);
        setLoading(false);
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const refreshFiles = () => {
    getAllFilesFromFolder(currentFolderId)
      .then(res => {
        mountFilesTable(res?.files);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refresh();
        setLoading(false);
      });
  };
  const fileDeletion = fileId => {
    deleteFile(fileId)
      .then(() => {
        const newData = [...fileData];

        const indx = newData.map(d => d.fileId).indexOf(fileId);
        if (indx) {
          newData.splice(indx, 1);
          setFileData(newData);
        }
        message.success("Arquivo excluido com sucesso!");
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        refreshFiles(currentFolderId);
        refresh();
      });
  };

  const columnsFiles = [
    {
      title: "Arquivos",
      dataIndex: "filename",
      key: "filename",
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <Tooltip placement="topLeft" title="editar">
            <Button
              onClick={() => handleOpenMetadataModal(record.id)}
              shape="circle"
              type="ghost"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="excluir">
            <Button
              shape="circle"
              type="ghost"
              icon={<DeleteOutlined style={{ color: "#ff0000" }} />}
              onClick={() => {
                setSelectedFile(record);
                handleOpenFileModalConfirm();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllFolders()
      .then(res => {
        mountFoldersTable(res);
        setLoading(false);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally();
  }, []);

  const refresh = () => {
    getAllFolders()
      .then(response => {
        mountFoldersTable(response);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(setLoading(false));
  };

  const mountFoldersTable = folders => {
    folders = folders?.map(folder => {
      folder["key"] = folder?.folder_id;

      return folder;
    });
    setFolderData(folders);
  };
  const mountFilesTable = files => {
    files = files?.map(file => {
      file["key"] = file.id;
      return file;
    });
    setFileData(files);
  };

  const handleCloseFolderModal = () => {
    setEditMode(false);
    setInitialValue({});
    setOpenFolderModal(false);
  };

  const handleOpenMetadataModal = fileId => {
    setCurrentMetadataFileId(fileId);
    setOpenMetadataModal(true);
  };

  const onCloseMetadataModal = () => {
    setOpenMetadataModal(false);
  };

  const handleCloseConfirmFolderModal = () => setOpenConfirmFolderModal(prevState => !prevState);

  const handleOpenFolderModalConfirm = () => setOpenConfirmFolderModal(true);

  const handleCloseConfirmFileModal = () => setOpenConfirmFileModal(prevState => !prevState);

  const handleOpenFileModalConfirm = () => setOpenConfirmFileModal(true);

  // eslint-disable-next-line no-shadow
  const handleFolderDelete = selectedFolder => {
    folderDeletion(selectedFolder.folder_id);
    handleCloseConfirmFolderModal();
  };

  // eslint-disable-next-line no-shadow
  const handleFileDelete = selectedFile => {
    fileDeletion(selectedFile.id);
    handleCloseConfirmFileModal();
  };

  return (
    <>
      <div className={styles.space_align_container}>
        <div>
          <div className={styles.documents_button}>
            <Button type="primary" onClick={() => setOpenFolderModal(true)}>
              Criar pasta
            </Button>
            <FoldersModal
              open={openFolderModal}
              handleCloseModal={handleCloseFolderModal}
              id={selectedFolder && selectedFolder.folder_id}
              initialValues={initialValue}
              isEditingMode={editMode}
              mountTable={mountFoldersTable}
            />
          </div>
          <div>
            <Spin className={styles.services_spinner} spinning={loading} size="large" />
            {columnsFolder && folderData && (
              <Table
                className={styles.table}
                dataSource={folderData}
                columns={columnsFolder}
                pagination={{ showSizeChanger: folderData.length > 10 }}
              />
            )}
          </div>
        </div>
        <div>
          <div className={styles.documents_button}>
            <DocumentsModal name="files" refreshTable={refreshFiles} folderId={currentFolderId} />
          </div>
          <div>
            <Spin className={styles.services_spinner} spinning={loading} size="large" />
            {columnsFiles && fileData && !loading && (
              <Table
                className={styles.table}
                dataSource={fileData}
                columns={columnsFiles}
                pagination={{ showSizeChanger: fileData.length > 10 }}
              />
            )}
          </div>
        </div>
      </div>
      <MetadataModal
        open={openMetadataModal}
        fileId={currentMetadataFileId}
        onClose={onCloseMetadataModal}
      />
      <ConfirmModal
        title={
          selectedFolder != null
            ? `Você está prestes a remover a pasta "${selectedFolder.foldername}".`
            : ""
        }
        message="A ação não poderá ser desfeita. Você tem certeza?"
        open={openConfirmFolderModal}
        onClose={handleCloseConfirmFolderModal}
        isLoading={loading}
        type="delete"
        onConfirm={() => handleFolderDelete(selectedFolder)}
      />
      <ConfirmModal
        title={
          selectedFile != null
            ? `Você está prestes a remover o arquivo "${selectedFile.filename}".`
            : ""
        }
        message="A ação não poderá ser desfeita. Você tem certeza?"
        open={openConfirmFileModal}
        onClose={handleCloseConfirmFileModal}
        isLoading={loading}
        type="delete"
        onConfirm={() => handleFileDelete(selectedFile)}
      />
    </>
  );
}
export { Documents };
