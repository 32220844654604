export function login(user) {
    return {
      type: 'LOGIN',
      payload: user,
    };
  }
  export function isAuthenticated(user) {
    return {
      type: 'IS_AUTHENTICATED',
      payload: user,
    };
  }
  export function logout() {
    return {
      type: 'LOGOUT',
    };
  }
  