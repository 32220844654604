/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Input, message, Space, Spin, Table, Tooltip } from "antd";
import { createModule, getAllModules, updateModule } from "api/Etl";
import { DeleteWithPasswordModal } from "components/DeleteModal/DeleteWithPasswordModal";
import React, { useState, useEffect } from "react";
import { modules } from "utils/mock/modules";
import { CreateModulesModal } from "./CreateModulesModal";
import styles from "./Modules.module.scss";
import { ModulesDetailsModal } from "./ModulesDetailsModal";

function Modules() {
  const { Search } = Input;
  const [modulesList, setModulesList] = useState([]);
  const [openCreateModuleModal, setOpenCreateModuleModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchModules = () => {
    setLoading(true);
    getAllModules().then(resp => {
      setModulesList(normalizedModules(resp));
      setLoading(false);
    });
  };

  const normalizedModules = arr =>
    arr.map(it => ({
      key: `${it.id}`,
      alias: it.alias,
      description: it.description,
    }));

  useEffect(() => {
    fetchModules();
  }, []);

  const handleOpenModuleModal = () => {
    setOpenCreateModuleModal(true);
  };

  const handleCloseModuleModal = () => {
    setSelectedModule(null);
    setOpenCreateModuleModal(false);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDetailsModal = () => {
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const handleUpdateModule = data => {
    const id = parseInt(selectedModule.key, 10);
    updateModule(id, data)
      .then(() => {
        message.success("Módulo atualizado com sucesso!");
        fetchModules();
        handleCloseModuleModal();
      })
      .catch(e => {
        message.error("Erro ao atualizar módulo!");
      });
  };

  const handleCreateModule = (data, code) => {
    createModule(data, code)
      .then(() => {
        message.success("Módulo criado com sucesso!");
        fetchModules();
        handleCloseModuleModal();
      })
      .catch(() => {
        message.error("Erro ao criar módulo!");
      });
  };

  const columns = [
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      sorter: {
        compare: (a, b) => a.alias.localeCompare(b.alias),
      },
    },
    { title: "Descrição", dataIndex: "description", key: "description" },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="topLeft" title="Detalhes">
            <Button
              shape="circle"
              type="ghost"
              icon={<UnorderedListOutlined />}
              onClick={() => {
                setSelectedModule(record);
                handleOpenDetailsModal();
              }}
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="Editar">
            <Button
              shape="circle"
              type="ghost"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedModule(record);
                handleOpenModuleModal();
              }}
            />
          </Tooltip>
          <Tooltip placement="topLeft" title="Excluir">
            <Button
              shape="circle"
              type="ghost"
              icon={<DeleteOutlined />}
              disabled
              onClick={() => {
                setSelectedModule(record);
                handleOpenDeleteModal();
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className={`${styles.container_align_right}`}>
        <div className={styles.link_manual}>
          <Button type="link" href="/manual/modules">
            <QuestionCircleOutlined /> Visite a documentação para criação de módulos
          </Button>
        </div>
        <Button
          type="primary"
          onClick={() => {
            setSelectedModule(null);
            handleOpenModuleModal();
          }}
        >
          Criar módulo
        </Button>
      </div>
      <Spin className={styles.services_spinner} spinning={loading} size="large" />
      {modulesList.length > 0 && (
        <div className={styles.settings_listing}>
          <Table className={styles.table} columns={columns} dataSource={modulesList} />
        </div>
      )}
      <CreateModulesModal
        loading={loading}
        module={selectedModule}
        onClose={handleCloseModuleModal}
        onConfirm={selectedModule ? handleUpdateModule : handleCreateModule}
        open={openCreateModuleModal}
      />
      <ModulesDetailsModal
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        module={selectedModule}
      />
      <DeleteWithPasswordModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        password={selectedModule?.alias}
        onConfirm={() => {}}
        item={selectedModule}
      />
    </div>
  );
}

export { Modules };
