/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  message,
  Spin,
  Button,
  // Space,
  Typography,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Divider,
  Tabs,
  Row,
  Col,
} from "antd";
import styles from "./TokenModal.module.scss";
import {
  createToken,
  getAllAvailableServices,
  getAllTokens,
} from "../../../api/Tokens/tokens.service";

// eslint-disable-next-line react/function-component-definition
const TokenModal = ({ isVisible, closeModal, mountTable }) => {
  const { Option } = Select;

  const [availableServices, setAvailableServices] = useState();
  const [permissions, setPermissions] = useState();

  const [isCreating, setIsCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkboxes = [
    { label: "Ler (Endpoints GET)", value: "read", checked: true },
    { label: "Criar (Endpoints POST)", value: "create", checked: true },
    { label: "Atualizar (Endpoints PUT)", value: "update", checked: true },
    { label: "Deletar (Endpoints DELETE)", value: "delete", checked: true },
  ];

  useEffect(() => {
    getAllAvailableServices()
      .then(res => {
        setAvailableServices(res);
        const permissions = {};
        res.forEach(service => {
          permissions[service["name"]] = checkboxes;
        });
        setPermissions(permissions);
      })
      .catch(err => {
        message.error(err.message);
      });
  }, [isVisible]);

  const refresh = () => {
    getAllTokens()
      .then(response => {
        mountTable(response);
      })
      .finally();
  };

  const onFinish = data => {
    setLoading(true);

    const finalPermissions = [];
    for (const svcName of Object.keys(permissions)) {
      finalPermissions.push({
        service_name: svcName,
        read: permissions[svcName][0]["checked"],
        create: permissions[svcName][1]["checked"],
        update: permissions[svcName][2]["checked"],
        delete: permissions[svcName][3]["checked"],
      });
    }

    data["permissions"] = finalPermissions;
    data["domains"] = data["domains"].split(",");
    data["expiration_date"] = data["expiration_date"].format("YYYY-MM-DD[T]HH:mm:ss");

    console.log(JSON.stringify(data));

    setIsCreating(true);
    createToken(JSON.stringify(data))
      .then(() => {
        message.success("Token criado com sucesso!");
        closeModal();
        setLoading(false);
        refresh();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  const onFinishFailed = data => {
    // eslint-disable-next-line no-console
    console.log("onFinishFailed data", data);
  };

  const checkboxClicked = (serviceName, value) => {
    const checkboxes = [
      {
        label: "Ler (Endpoints GET)",
        value: "read",
        checked: value.includes("read"),
      },
      {
        label: "Criar (Endpoints POST)",
        value: "create",
        checked: value.includes("create"),
      },
      {
        label: "Atualizar (Endpoints PUT)",
        value: "update",
        checked: value.includes("update"),
      },
      {
        label: "Deletar (Endpoints DELETE)",
        value: "delete",
        checked: value.includes("delete"),
      },
    ];
    const newPermissions = { ...permissions };
    newPermissions[serviceName] = checkboxes;
    setPermissions(newPermissions);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title="Criação de token"
      visible={isVisible}
      onCancel={() => closeModal()}
      footer={[
        <Button disabled={isCreating} type="primary" form="myForm" key="submit" htmlType="submit">
          <Spin spinning={loading} size="small" />
          Criar
        </Button>,
        <Button disabled={isCreating} key="back" onClick={() => closeModal()}>
          Cancelar
        </Button>,
      ]}
    >
      <Form
        name="myForm"
        layout="horizontal"
        initialValues={{
          expiration_date: moment(moment().add(12, "M"), "DD-MM-YYYY"),
          token_type: "HOMOLOG",
          domains: "*",
          enabled: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Alias"
              name="alias"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Nome que remeta a aplicação que o token irá ser utilizado"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o alias!",
                },
              ]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="E-mail do responsável pelo token"
              name="responsible_email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Quem será responsável pelo uso do  token e responderá por ele"
              rules={[
                {
                  required: true,
                  message: "Por favor, informe o e-mail do responsável!",
                },
              ]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Tipo de token"
              name="token_type"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              rules={[{ required: true }]}
            >
              <Select disabled={isCreating}>
                <Option value="HOMOLOG">Homologação</Option>
                <Option value="PRODUCTION">Produção</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Data de expiração"
              name="expiration_date"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              rules={[{ required: true }]}
            >
              <DatePicker
                className={styles.data_placeholder_input}
                disabled={isCreating}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Domínios"
              name="domains"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Domínios separados por vírgula. Pode conter wildcard (ex: http://localhost:* ou http:*.sebrae-sc.com.br)"
              rules={[{ required: true, message: "Por favor, informe o dóminio!" }]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="SAS Token"
              name="sas_token"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              tooltip="Token da aplicação SAS que será utilizado por esse token."
              rules={[{ required: false }]}
            >
              <Input disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Habilitado para uso"
              name="enabled"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Checkbox disabled={isCreating} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <div direction="vertical" align="center">
          <Typography.Title level={4} align="left">
            Permissões
          </Typography.Title>
          <Tabs tabPosition="left">
            {availableServices &&
              permissions &&
              availableServices.map((service, index) => (
                <React.Fragment key={index}>
                  <Tabs.TabPane
                    tab={service["alias"]}
                    key={index}
                    className={styles.tabs_align_left}
                  >
                    <Checkbox.Group
                      className={styles.checkbox_group}
                      disabled={isCreating}
                      defaultValue={["read", "create", "update", "delete"]}
                      options={permissions[service["name"]]}
                      onChange={e => checkboxClicked(service["name"], e)}
                    />
                  </Tabs.TabPane>
                </React.Fragment>
              ))}
          </Tabs>
        </div>
      </Form>
    </Modal>
  );
};

export { TokenModal };
