/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { authService } from "api";
import styles from "./ForgotPassword.module.scss";

function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = data => {
    setLoading(true);
    authService.recoverPassword(data.email).finally(() => {
      message.info(
        "Enviamos uma mensagem para o e-mail informado para a recuperação de senha, caso ele exista em nosso sistema.",
      );
      navigate("/login");
    });
  };

  return (
    <div>
      <div className={styles.login_page}>
        <div className={styles.login_box}>
          <div className={styles.illustration_wrapper}>
            <img
              src="https://fabricacom.com.br/wp-content/uploads/2018/07/ARTE_SITE_CLIENTES_Prancheta-1-copy-11.jpg"
              alt="Login"
            />
          </div>

          <Form name="login-form" id="login-form" className={styles.login_form} onFinish={onFinish}>
            <p>Informe o e-mail de cadastro para recuperação de senha:</p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Informe o email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.login_form_button}
                disabled={loading}
              >
                ENVIAR
              </Button>
              <Button type="link" onClick={() => navigate("/login")}>
                {"< Voltar"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export { ForgotPasswordPage };
