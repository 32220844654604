export const CronLocalePtBR = {
  everyText: "todo",
  emptyMonths: "todo Mês",
  emptyMonthDays: "todos os dias do mês",
  emptyMonthDaysShort: "dia do mês",
  emptyWeekDays: "todos os dias da semana",
  emptyWeekDaysShort: "dia da semana",
  emptyHours: "todas as horas",
  emptyMinutes: "todos os minutos",
  emptyMinutesForHourPeriod: "todos",
  yearOption: "ano",
  monthOption: "mês",
  weekOption: "semana",
  dayOption: "dia",
  hourOption: "hora",
  minuteOption: "minuto",
  rebootOption: "reiniciar",
  prefixPeriod: "Todo(a)",
  prefixMonths: "em",
  prefixMonthDays: "em",
  prefixWeekDays: "no(a)",
  prefixWeekDaysForMonthAndYearPeriod: "no(a)",
  prefixHours: "às",
  prefixMinutes: ":",
  prefixMinutesForHourPeriod: "às",
  suffixMinutesForHourPeriod: "minutos",
  errorInvalidCron: "Cron inválido",
  clearButtonText: "Limpar",
  weekDays: [
    // Order is important, the index will be used as value
    "Domingo", // Sunday must always be first, it's "0"
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ],
  months: [
    // Order is important, the index will be used as value
    "Janeiro", // January must always be first, it's "0"
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  // Order is important, the index will be used as value
  altWeekDays: [
    "DOM", // Sunday must always be first, it's "0"
    "SEG",
    "TER",
    "QUA",
    "QUI",
    "SEX",
    "SAB",
  ],
  // Order is important, the index will be used as value
  altMonths: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"],
};
