/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

const urlBase = window._env_.REACT_APP_ETL_URL || process.env.REACT_APP_ETL_URL;

async function getAllJobs() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job`, requestOptions);
  return await middlewareDefault(response);
}

async function createJob(data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteJob(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function getJob(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function updateJob(id, data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function toggleJobStatus(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${id}/toggle_status`, requestOptions);
  return await middlewareDefault(response);
}

async function getModulesRelatedByJob(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${id}/module`, requestOptions);
  return await middlewareDefault(response);
}

async function addModulesToJob(jobId, modulesId) {
  const token = window.localStorage.getItem("token");
  const data = JSON.stringify(modulesId);
  const requestOptions = {
    method: "POST",
    body: data,
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${jobId}/module`, requestOptions);
  return await middlewareDefault(response);
}

async function removeModulesToJob(jobId, moduleId) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/job/${jobId}/module/${moduleId}`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllModules() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/module`, requestOptions);
  return await middlewareDefault(response);
}

async function createModule(data, codeFile) {
  const token = window.localStorage.getItem("token");
  const formData = new FormData();
  formData.append("alias", data.alias);
  formData.append("description", data.description);
  formData.append("main_file", codeFile);

  const requestOptions = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/module`, requestOptions);
  return await middlewareDefault(response);
}

async function updateModule(id, data) {
  const token = window.localStorage.getItem("token");
  const formData = new FormData();
  formData.append("alias", data.alias);
  formData.append("description", data.description);

  const requestOptions = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/module/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function getModuleFile(id) {
  const token = window.localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/module/${id}/file`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllExecutions() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/execution`, requestOptions);
  return await middlewareDefault(response);
}

async function getJobExecutionLog(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/execution/jobs/${id}/logs?id=${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function getModuleExecutionLog(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/execution/modules/${id}/logs`, requestOptions);
  return await middlewareDefault(response);
}

export {
  getAllJobs,
  createJob,
  deleteJob,
  getJob,
  updateJob,
  toggleJobStatus,
  getModulesRelatedByJob,
  addModulesToJob,
  removeModulesToJob,
  getAllModules,
  createModule,
  updateModule,
  getAllExecutions,
  getJobExecutionLog,
  getModuleExecutionLog,
  getModuleFile,
};
