import { middlewareDefault } from "../middleware";

const urlBase = window._env_.REACT_APP_TOKENS_URL || process.env.REACT_APP_TOKENS_URL;

async function getAllTokens() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/token/`, requestOptions);
  return await middlewareDefault(response);
}

async function getTokenDetails(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/token/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteToken(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/token/${id}`, requestOptions);
  return await middlewareDefault(response);
}

async function getAllAvailableServices() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/token/services`, requestOptions);
  return await middlewareDefault(response);
}

async function createToken(data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(`${urlBase}/token`, requestOptions);
  return await middlewareDefault(response);
}

async function editToken(id, data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(`${urlBase}/token/${id}`, requestOptions);
  return await middlewareDefault(response);
}

export {
  getAllTokens,
  getTokenDetails,
  deleteToken,
  getAllAvailableServices,
  createToken,
  editToken,
};
