import './App.css'

import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { RootReducer } from './store/index';
import { LisRoutes } from './routes/routes';

import {LoginPage} from './containers';


function App() {
  const store = createStore(RootReducer, compose(applyMiddleware(thunk)));

  return (
    <Provider store={store}>
      <LisRoutes>
        <LoginPage />
      </LisRoutes>
    </Provider>
  );
}

export default App;
