import React from "react";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
// eslint-disable-next-line import/no-unresolved
import { Row, Col, Typography } from "antd";
import styles from "./Changelog.module.scss";

function Changelog() {
  const changelog = [
    {
      initial_date: "15/01/2024",
      end_date: "26/01/2024",
      changes: [
        {
          title: "Smart chatbot",
          topics: [
            "Desenvolvimento do registro de atendimento ao realizar uma conversa com o smart chatbot",
            "Exibição da fonte utilizada pelo modelo para gerar a resposta como link clicável",
            "Armazenamento e utilização dos textos completos como contexto das respostas",
            "Melhorias nas respostas geradas a partir da base do 0800",
          ],
        },
        {
          title: "INFRA & DEVOPS",
          topics: [
            "Migração do ElasticSearch para o ambiente do SEBRAE",
            "Migração do PostgreSQL para o ambiente do SEBRAE",
            "Migração do CounchDB para o ambiente do SEBRAE",
          ],
        },
      ],
    },
    {
      initial_date: "29/01/2024",
      end_date: "09/02/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Criação do endpoint do CRUD de conexões entre pessoas",
            "Integração do WebApp com o Portal do Sebrae-SC para disponibilização do conteúdo do Portal no APP",
          ],
        },
        {
          title: "INFRA & DEVOPS",
          topics: [
            "Criação do Projeto do WebApp Sebrae Delas",
            "Criação de pipelines de validação e empacotamento",
            "Criação de pipeline de deploy do WebApp",
            "Preparação do ambiente para publicar o WebApp",
          ],
        },
      ],
    },
    {
      initial_date: "19/02/2024",
      end_date: "01/03/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Autenticação no WebApp com integração com AMEI",
            "Integração com o SAS para obter dados dos usuários",
            "Criação do endpoint do CRUD de contatos e redes sociais para facilitar a comunicação entre os usuários",
            "Desenvolvimento de parte da tela de visualização e edição do perfil usuário - CRUD de contatos e redes sociais para facilitar a comunicação entre os usuários",
            "Criação do endpoint do CRUD para posts considerando textos e imagens",
            "Integração do smart chatbot com o WebApp sebrae delas",
            "Integração do WebApp com o serviço de recomendação da LIS",
          ],
        },
        {
          title: "INFRA & DEVOPS",
          topics: ["Ajuste no serviço auth - swagger não estava sendo encontrado"],
        },
      ],
    },
    {
      initial_date: "04/03/2024",
      end_date: "15/03/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Desenvolvimento do front-end do CRUD de conexões entre pessoas",
            "Desenvolvimento da visualizar das recomendações de conteúdo no WebApp",
            "Desenvolvimento da visualização do conteúdo do portal do Sebrae-SC no WebApp",
            "Criação do endpoint do CRUD de necessidades e interesses",
          ],
        },
        {
          title: "Smart chatbot",
          topics: [
            "Ajustes no registro de atendimento com smartchat (ajustes devido a utilização do AMEI)",
            "Disponibilização do crawler do 0800 para o SmartLake",
          ],
        },
        {
          title: "INFRA & DEVOPS",
          topics: [
            "Criação de instância do Elasticsearch no Cluster",
            "Criação de instância do CouchDB no Cluster",
            "Criação de instância do ArangoDB no Cluster",
          ],
        },
      ],
    },
    {
      initial_date: "18/03/2024",
      end_date: "01/04/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Desenvolvimento do serviço de recomendação de conexões (recomendações iniciais para gerar as primeiras conexões entre pessoas no grafo)",
            "Estruturação do webApp de acordo com os mockups das telas",
            "Construção da rede de relacionamento visando o webApp Sebrae Delas",
            "Utilização das necessidades/interesses cadastrados nos algoritmos de grafo",
            "Desenvolvimento do front-end de recomendações de conexões",
            "Desenvolvimento do front-end do CRUD para posts, considerando textos e imagen",
            "Criação do endpoint do CRUD para curtidas em posts",
            "Desenvolvimento do front-end do CRUD para curtidas em posts - Tela do feed",
            "Desenvolvimento do front-end do CRUD de necessidades e interesses",
            "Integração com o projeto e ação do Delas para registro de atendimento utilizando SAS",
            "Registro de histórico de atendimento no SAS",
          ],
        },
      ],
    },
    {
      initial_date: "08/04/2024",
      end_date: "19/04/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Adequação do layout das telas já feitas de acordo com os mockups",
            "Desenvolvimento da tela de perfil de outro usuário",
            "Correção do BUG dos usuários duplicados na tela de conexões",
            "Desenvolvimento da tela de menu",
            "Desenvolvimento da tela de boas vindas",
            "Desenvolvimento da sinalização do primeiro acesso da pessoa",
            "Criação do endpoint para atualização das informações do perfil do usuário",
            "Desenvolvimento da tela de explorar - Aba Tudo, Aba Pessoas e Aba Publicações",
            "Desenvolvimento da tela de edição do perfil do usuário",
            "Estruturação do projeto de grafos",
          ],
        },
      ],
    },
    {
      initial_date: "22/04/2024",
      end_date: "03/05/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Desenvolvimento da tela de ajuda e suporte",
            "Desenvolvimento da tela de sobre",
            "Desenvolvimento da Pesquisa na tela de explorar",
            "Desenvolvimento da recomendação de conexões para a aba Pessoas da tela de explorar",
            "Desenvolvimento do filtro textual na API do Sebrae Delas para Pesquisa da tela de explorar",
            "Criação do endpoint do CRUD de fotos e textos para as telas de boas vindas",
            "Integração do front-end das telas de boas vindas com o endpoint do CRUD de fotos e textos",
            "Desenvolvimento da recomendação de posts para a aba Publicações da tela de explorar",
            "Correção do BUG do Login",
          ],
        },
        {
          title: "Serviço de recomendação",
          topics: [
            "Criação do ETL para extrair os dados da API do Sebrae Nacional e armazenar no ElasticSearch",
            "Geração das recomendações para a base de dados do Sebrae Nacional",
            "Criação do parâmetro de contexto no serviço de recomendação",
            "Criação de endpoints para captura de indicadores em relação a efetividade das recomendações",
          ],
        },
        {
          title: "INFRA & DEVOPS",
          topics: ["Migração da instância do banco Postgres para o cluster do Sebrae"],
        },
        {
          title: "Smart Chatbot",
          topics: ["Recriação do banco de dados do smart chatbot com nova janela de contexto"],
        },
      ],
    },
    {
      initial_date: "06/05/2024",
      end_date: "17/05/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Desenvolvimento do tour de boas vindas",
            "Perfil administrador/gestor - Configuração do ROLE do usuário (admin/comum)",
            "Perfil administrador/gestor - Criação do endpoint para visualização de todos os posts (fechado para admin)",
            "Perfil administrador/gestor - Habilitação da remoção de post sem ser owner, caso seja admin",
            "Perfil administrador/gestor - Criação de endpoint de exclusão de usuário",
            "Perfil administrador/gestor - Criação do CRUD de imagens de boas-vindas",
            "Perfil administrador/gestor - Instanciação do novo projeto para ADMIN",
            "Blob Storage - Conexão com blob storage",
            "Blob Storage - Inserção de mídias no blob storage na criação de posts",
            "Blob Storage - Armazenamento da URL do blob storage",
            "Desenvolvimento da aba de Conteúdos na tela de Explorar",
            "Adição da bio e das redes sociais no perfil do usuário",
            "Desenvolvimento do carrossel de recomendação de conteúdos no feed do webApp",
            "Correção do bug na tela do perfil de outro usuário com redes sociais",
            "Correção do bug na pesquisa da tela Explorar",
          ],
        },
        {
          title: "Smart Chatbot",
          topics: ["Desenvolvimento do uso do feedback dos usuários pelo Smart Chatbot"],
        },
        {
          title: "Serviço de recomendação",
          topics: [
            "Refatoração do código do serviço de recomendação para aceitar novos contextos de aplicações",
          ],
        },
        {
          title: "Criação de usuários na plataforma administrativa da LIS",
          topics: ["Correção do bug da criação do usuário", "Tratativas sobre Token"],
        },
      ],
    },
    {
      initial_date: "20/05/2024",
      end_date: "31/05/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Push notifications - Integração do back-end com a plataforma de push notification",
            "Push notifications - Controle de canais habilitados por usuário",
            "Push notifications - Configuração do gatilho de push para nova solicitação de contato",
            "Push notifications - Configuração do gatilho de push para curtida",
            "Push notifications - Configuração do gatilho de push para novos posts",
            "Push notifications - Criação do CRUD de canais de push notification",
            "Tela de inclusão de empresas - Criação do CRUD de empresas",
            "Tela de inclusão de empresas - Integração com o SAS quando o usuário criar ou editar empresas",
            "Perfil Masculino - Bloquear o registro de usuários do sexo masculino",
            "Perfil administrador/gestor - Criação da Tela de posts",
            "Visualização das redes sociais no perfil do usuário logado",
            "Alteração da Lis para LIS em todo sistema e alteração da foto da LIS",
            "Captura das imagens dos conteúdos no banco para exibir no carrossel de conteúdos",
            "Paginação do feed",
          ],
        },
        {
          title: "Smart Chatbot",
          topics: ["Treinamento do smart chatbot com os dados do manual do Emissor de NFe"],
        },
      ],
    },
    {
      initial_date: "03/06/2024",
      end_date: "14/06/2024",
      changes: [
        {
          title: "WebApp Sebrae Delas",
          topics: [
            "Tela de inclusão de empresas - Desenvolvimento do front-end da tela de adicionar empresas",
            "Tela de mensagens - Configuração do web socket para troca de mensagens",
            "Tela de mensagens - Criação do canal no web socket para troca de mensagens por usuário",
            "Tela de mensagens - Criação do endpoint para envio de mensagens",
            "Tela de mensagens - Criação do endpoint para listar as mensagens de um dado usuário",
            "Notificações dentro do App - Criação do CRUD de notificações no back-end",
            "Notificações dentro do App - Desenvolvimento do controle de mensagens não lidas/lidas",
            "Perfil administrador/gestor - Criação da tela de login (AMEI), testando se usuário é ROLE ADMIN para acesso",
            "Perfil administrador/gestor - Desenvolvimento do front-end da tela de usuários",
            "Perfil administrador/gestor - Desenvolvimento do front-end da tela de configurações",
            "Tela de interesses - Alteração na tela para permitir a inclusão de interesses abertos",
          ],
        },
        {
          title: "Smart Chatbot",
          topics: ["Treinamento do smart chatbot com os dados da API do Click"],
        },
        {
          title: "Indicadores de recomendação",
          topics: [
            "Desenvolvimento de um job para geração da base de dados para alimentar o dashboard de Indicadores de Recomendação",
          ],
        },
      ],
    },
  ];

  const reversedChangelog = [...changelog].reverse();

  return (
    <PageLayout title="LIS - Changelog" subTitle="Mudanças realizadas nas versões da LIS">
      <Row gutter={16} className={styles.row_margin}>
        <Col className={`${styles.flex_column} gutter-row`} xs={24} md={12}>
          {reversedChangelog?.map((log, index) => (
            <div key={index}>
              <h3>
                Changelog de {log.initial_date} à {log.end_date}
              </h3>
              <article className={styles.container}>
                <Typography>
                  {log.changes.map((change, index2) => (
                    <div key={index2}>
                      <b>- {change.title}</b>
                      <br />
                      {change.topics.map((topic, index3) => (
                        <div key={index3}>
                          <span className={styles.description}>{topic}</span>
                          <br />
                        </div>
                      ))}
                      <br />
                      <br />
                    </div>
                  ))}
                </Typography>
              </article>
            </div>
          ))}
        </Col>
      </Row>
    </PageLayout>
  );
}

export { Changelog };
