/* eslint-disable react/destructuring-assignment */
/* eslint-disable default-param-last */
/* eslint-disable indent */
import { authConstants } from '../constants';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
};

function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case authConstants.IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload != null,
        user: action.payload,
      };
    case authConstants.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}

export { AuthReducer };
