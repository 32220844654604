/* eslint-disable import/no-unresolved */
import { Menu } from "antd";
import { useUserAccess } from "hooks";
import styles from "./PageLayout.module.scss";

export function PageMenu() {
  const { menuList } = useUserAccess();

  const getDefaultKey = () => {
    const { pathname } = window.location;

    if (pathname.startsWith("/services")) return "services";
    if (pathname.startsWith("/tokens")) return "tokens";
    if (pathname.startsWith("/metadata")) return "metadata";
    if (pathname.startsWith("/about")) return "about";
    if (pathname.startsWith("/settings")) return "settings";
    if (pathname.startsWith("/manual")) return "services";
    if (pathname.startsWith("/changelog")) return "changelog";
    return "users";
  };

  const defaultSelectedKey = getDefaultKey();

  return (
    <Menu
      className={styles.menu_font_size}
      defaultSelectedKeys={[defaultSelectedKey || "services"]}
      mode="inline"
      theme="dark"
      items={menuList}
    />
  );
}
