// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

// import { middlewareDefault } from "api/middleware";
const urlBase = window._env_.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_URL;

async function login(username, password) {
  const formData = new FormData();

  formData.append("username", username);
  formData.append("password", password);

  const requestOptions = {
    method: "POST",
    body: formData,
  };

  const response = await fetch(`${urlBase}/auth/`, requestOptions);
  const clonedResponse = await response.clone();
  const consumedResponse = await clonedResponse.json();
  if (response.ok) {
    window.localStorage.setItem("token", consumedResponse.access_token);
    window.localStorage.setItem("userData", JSON.stringify(consumedResponse.data));
  }
  return await middlewareDefault(response);
}

async function isAuthenticated() {
  const token = window.localStorage.getItem("token");
  return token;
}

function logout() {
  window.localStorage.removeItem("token");
  window.location = "/login";
}

async function recoverPassword(email) {
  const requestOptions = {
    method: "POST",
  };

  const response = await fetch(
    `${urlBase}/user/forgot-password/generate?email=${email}`,
    requestOptions,
  );
  return await middlewareDefault(response);
}

export const authService = {
  login,
  logout,
  isAuthenticated,
  recoverPassword,
};
