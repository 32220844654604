/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useState } from "react";
import { Button, Layout, Grid, PageHeader, Typography, Tooltip, Divider } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { UserProfile } from "components/UserProfile";
import styles from "./PageLayout.module.scss";
import { FooterMenu } from "./FooterMenu";
import { PageMenu } from "./PageMenu";

const { useBreakpoint } = Grid;

function PageLayout(props) {
  const { Header, Footer, Sider, Content } = Layout;
  const [openMenu, setOpenMenu] = useState(true);
  const { md } = useBreakpoint();
  const toggleOpenMenu = () => setOpenMenu(prevState => !prevState);

  return (
    <Layout className={styles.layout_height}>
      <Sider
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={!openMenu ?? !md}
        className={styles.sider}
      >
        <div className={styles.sidebar_container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: md && !openMenu ? "center" : "flex-end",
            }}
          >
            {md && openMenu && (
              <Typography.Title className={styles.typography_title} level={5}>
                LIS-Sebrae
              </Typography.Title>
            )}
            <Tooltip placement="topRight" title="Menu">
              <Button
                type="text"
                onClick={toggleOpenMenu}
                icon={
                  md && !openMenu ? (
                    <MenuUnfoldOutlined className={styles.menu_outlined} />
                  ) : (
                    <MenuFoldOutlined className={styles.menu_outlined} />
                  )
                }
              />
            </Tooltip>
          </div>
          <PageMenu />
          <div className={styles.page_menu_padding}>
            <Divider className={styles.divider} />
            <div>
              <FooterMenu />
            </div>
          </div>
        </div>
      </Sider>
      <Layout className={styles.layout_margin_left}>
        <Header className={styles.site_layout_sub_header}>
          <div className={styles.container_profile}>
            <UserProfile />
          </div>
        </Header>
        <Content>
          <PageHeader
            className={styles.site_page_header}
            title={props.title || ""}
            subTitle={props.subTitle || ""}
          />
          <div className={styles.site_page_content}>{props.children}</div>
        </Content>
        <Footer>Powered by LIS</Footer>
      </Layout>
    </Layout>
  );
}

export { PageLayout };
