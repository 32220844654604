/* eslint-disable import/no-relative-packages */
/* eslint-disable react/prop-types */
// import { Badge, Button, Col, Modal, Row, Tag, Typography } from "antd";
// import React from "react";
// import moment from "moment";
// import { toString } from "cronstrue";
import { Button, Col, Modal, Row, Tag, Typography, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../node_modules/cronstrue/locales/pt_BR";
import styles from "./JobsDetailsModal.module.scss";
import { getModulesRelatedByJob } from "../../../api/Etl";

function JobsDetailsModal({ open, onClose, job, modules }) {
  const [jobModules, updateModules] = useState([]);
  const [loading, loadingModules] = useState(false);

  useEffect(() => {
    if (job) {
      loadingModules(true);
      getModulesRelatedByJob(job.id).then(data => {
        const ids = data.map(d => d.module_id);
        updateModules(modules.filter(module => ids.indexOf(module.id) !== -1));
        loadingModules(false);
      });
    }
  }, [job]);

  return (
    <Modal
      width="70%"
      title="Detalhes do Job"
      visible={open}
      getContainer={false}
      forceRender
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <strong>Alias:</strong> {job?.alias}
        </Col>
        {/* <Col span={8}>
          <strong>Status:</strong>
          <Badge  
            status={badgeTypes[job?.status || "error"]}
            text={statusTypes[job?.status || "error"]}
          />
        </Col> */}
        {/* <Col span={8}>
          <strong>Última Execução:</strong> {moment(job?.lastExecution).format("L LTS")}
        </Col> */}
        <Col span={24}>
          <strong>Descrição:</strong>
          <Typography>{job?.description}</Typography>
        </Col>
        {/* <Col span={24}>
          <strong>Expressão Cron:</strong> {job?.frequency}
          <Typography>{job ? toString(job?.frequency, { locale: "pt_BR" }) : ""}</Typography>
        </Col> */}
        <Col span={24}>
          <strong>Expressão Cron:</strong> {job?.frequency}
          <Typography>{job ? job.cron_expression : ""}</Typography>
        </Col>
        <Col span={24}>
          <strong>Módulos</strong>
        </Col>
        <Col span={24}>
          {loading && <Spin className={styles.services_spinner} spinning={loading} size="large" />}
          {!loading &&
            jobModules.length > 0 &&
            jobModules.map((item, index) => (
              <Tag key={index} color="#1890FF">
                {item.alias}
              </Tag>
            ))}
          {!loading && jobModules.length === 0 && "Nenhum módulo associado ao job"}
        </Col>
      </Row>
    </Modal>
  );
}

export { JobsDetailsModal };
