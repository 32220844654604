/* eslint-disable react/prop-types */
import React from "react";
import { Avatar, Button, Modal, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styles from "./ConfirmModal.module.scss";

// eslint-disable-next-line react/prop-types
function ConfirmModal({ open, onClose, onConfirm, title, message, isLoading, type }) {
  const isDeleteOption = type === "delete";

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      confirmLoading={isLoading}
      footer={[
        <Button key="confirm" type="primary" danger={isDeleteOption} onClick={onConfirm}>
          {isDeleteOption ? "Remover" : "Confirmar"}
        </Button>,
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
      ]}
    >
      <div className={styles.positioner}>
        {isDeleteOption && (
          <div className={styles.avatar_container}>
            <div>
              <Avatar size={75} className={styles.avatar}>
                <DeleteOutlined className={styles.icon_size} />
              </Avatar>
            </div>
          </div>
        )}
        <Typography className={styles.title}>{title}</Typography>
        <Typography.Paragraph className={styles.message}>{message}</Typography.Paragraph>
      </div>
    </Modal>
  );
}

export { ConfirmModal };
