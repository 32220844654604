/* eslint-disable no-multi-assign */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-fallthrough */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Spin, Tooltip } from "antd";
import { downloadFile } from "api/Documents";
import { useEffect, useMemo, useState } from "react";
import { PdfViewer } from "components/PdfViewer/PdfViewer";
import styles from "./DocumentVisualization.module.scss";

const getExtension = mimetype => {
  const parts = mimetype.split("/");
  return parts[parts.length - 1];
};

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

function DocumentVisualization({ fileId, fileName }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [blob, setBlob] = useState(null);

  const registerDownload = () => {
    if (fileId) {
      downloadFile(fileId).then(res => {
        saveData(res, fileName);
      });
    }
  };

  const saveData = (() => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return (blob, fileName) => {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  useEffect(() => {
    setLoading(true);
    if (fileId) {
      downloadFile(fileId)
        .then(file => {
          setFile(file);
          blobToBase64(file).then(base64 => setBlob(base64));
        })
        .finally(() => setLoading(false));
    }
  }, [fileId]);

  const DocumentEl = useMemo(() => {
    if (blob) {
      const ext = getExtension(file.type);
      switch (ext.toLowerCase()) {
        case "jpg":
        case "gif":
        case "bmp":
        case "png":
          return <img width="100%" title="fileVisualization" alt="fileVisualization" src={blob} />;
        case "pdf":
          return <PdfViewer blob={blob} />;
        case "doc":
        case "docx":
        case "txt":
          return <iframe width="100%" height="700" title="fileVisualization" src={blob} />;
        default:
          return (
            <Card title="Download" className={styles.card_modal}>
              <center>
                Vizualização não disponível, faça o download:{" "}
                <strong>
                  <Tooltip placement="topLeft" title="baixar">
                    <Button
                      title="Download do arquivo"
                      className={styles.plus_button}
                      onClick={() => registerDownload()}
                      type="ghost"
                      icon={<DownloadOutlined />}
                    />
                  </Tooltip>
                </strong>
              </center>
            </Card>
          );
      }
    }
  }, [blob]);

  return (
    <>
      {loading ? (
        <div className={styles.container_center}>
          <Spin size="large" />
        </div>
      ) : (
        DocumentEl
      )}
    </>
  );
}

export { DocumentVisualization };
