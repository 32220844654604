/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Modal, Button, message, Select, DatePicker, Spin, Row, Col } from "antd";
import { Form, Input } from "antd";
import { getAllUsers, createUsers } from "api/Users/users.service";
import { useUserAccess } from "hooks";
import styles from "./UserModal.module.scss";

// eslint-disable-next-line react/function-component-definition
const UserModal = ({ mountTable, pageIsLoading }) => {
  const nodeRef = React.useRef(null);
  const [visible, setVisible] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [loading, setLoading] = useState(false);

  const { role, user } = useUserAccess();
  const isAdminStateUser = role === "admin_estadual";

  const { Option } = Select;

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} necessário!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  const stateMapping = {
    AC: "Acre",
    AL: "Alagoas",
    AP: "Amapá",
    AM: "Amazonas",
    BA: "Bahia",
    CE: "Ceará",
    DF: "Distrito Federal",
    ES: "Espírito Santo",
    GO: "Goiás",
    MA: "Maranhão",
    MT: "Mato Grosso",
    MS: "Mato Grosso do Sul",
    MG: "Minas Gerais",
    PA: "Pará",
    PB: "Paraíba",
    PR: "Paraná",
    PE: "Pernambuco",
    PI: "Piauí",
    RJ: "Rio de Janeiro",
    RN: "Rio Grande do Norte",
    RS: "Rio Grande do Sul",
    RO: "Rondônia",
    RR: "Roraima",
    SC: "Santa Catarina",
    SP: "São Paulo",
    SE: "Sergipe",
    TO: "Tocantins",
  };

  const adminStateUserStateMapping = Object.entries(stateMapping).reduce((acc, [key, value]) => {
    if (key === user.state) {
      return { ...acc, [key]: value };
    }
    return { ...acc };
  }, {});

  const userTypeMapping = {
    usuario: "Comum",
    admin_estadual: "Estadual",
    admin_nacional: "Nacional",
  };

  const adminStateUserTypeMapping = {
    usuario: "Comum",
    admin_estadual: "Estadual",
  };

  const states = Object.keys(isAdminStateUser ? adminStateUserStateMapping : stateMapping).map(
    key => (
      <Option key={key} value={key}>
        {isAdminStateUser ? adminStateUserStateMapping[key] : stateMapping[key]}
      </Option>
    ),
  );

  const userType = Object.keys(isAdminStateUser ? adminStateUserTypeMapping : userTypeMapping).map(
    key => (
      <Option key={key} value={key}>
        {userTypeMapping[key]}
      </Option>
    ),
  );

  const onFinish = data => {
    setLoading(true);
    data["expiration_date"] = data["expiration_date"].format("YYYY-MM-DD[T]HH:mm:ss");
    data["enabled"] = true;

    setIsCreating(true);
    createUsers(JSON.stringify(data))
      .then(() => {
        message.success("Usuário criado com sucesso!");
        setVisible(false);
        refresh();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        setIsCreating(false);
        setLoading(false);
      });
  };

  const refresh = () => {
    getAllUsers()
      .then(res => {
        mountTable(res);
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  return (
    <>
      {!pageIsLoading && (
        <div className={styles.container_align_right}>
          <Button type="primary" onClick={() => setVisible(true)}>
            Adicionar usuário
          </Button>
        </div>
      )}
      <Modal
        transitionName=""
        maskTransitionName=""
        title="Adicionar novo usuário"
        centered
        nodeRef={nodeRef}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            disabled={isCreating || loading}
            type="primary"
            form="user-creation-form"
            key="submit"
            htmlType="submit"
          >
            <Spin spinning={loading} size="small" />
            Adicionar
          </Button>,
          <Button disabled={isCreating} key="back" onClick={() => setVisible(false)}>
            Cancelar
          </Button>,
        ]}
      >
        <Form
          name="user-creation-form"
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout="horizontal"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nome"
                type="text"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[
                  {
                    required: true,
                    message: "Insira seu Nome",
                  },
                ]}
              >
                <Input disabled={isCreating} placeholder="Nome" id="name" name="name" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="last_name"
                label="Sobrenome"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[{ required: true, message: "Insira seu sobrenome" }]}
              >
                <Input
                  disabled={isCreating}
                  placeholder="Sobrenome"
                  id="last_name"
                  name="last_name"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Insira um endereço de email válido",
                  },
                ]}
              >
                <Input
                  disabled={isCreating}
                  placeholder="Email"
                  id="email"
                  name="email"
                  type="email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="state"
                label="Estado"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                // tooltip="Selecione o Estado ao qual o usuário estará vinculado"
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isCreating}
                  // defaultValue="Estado"
                  overlay={states}
                  id="state"
                  name="state"
                  type="select"
                >
                  {states}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="user_type"
                label="Usuário"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                tooltip="Selecione o tipo de acesso que o usuário criado terá. Um usuário estadual poderá acessar apenas as informações do seu estado e um usuário nacional poderá acessar todas as informações do Brasil"
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isCreating}
                  // defaultValue="Tipo de Usuário"
                  overlay={userType}
                  id="user_type"
                  name="stuser_typeate"
                  type="select"
                >
                  {userType}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="expiration_date"
                label="Data de expiração"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                tooltip="Selecione a data de expiração do usuário"
                rules={[{ required: true }]}
              >
                <DatePicker
                  className={styles.data_placeholder_input}
                  disabled={isCreating}
                  placeholder="DD/MM/YYYY"
                  id="expiration_date"
                  name="expiration_date"
                  type="date"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export { UserModal };
