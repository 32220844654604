/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-duplicates
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Row, Col, Card, Popover, message, Button } from "antd";
// eslint-disable-next-line import/no-unresolved
import { PageLayout } from "components/PageLayout";
// eslint-disable-next-line import/no-unresolved
import { getAllServices } from "api/Services/services.service";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useUserAccess } from "hooks";
import styles from "./Services.module.scss";

// eslint-disable-next-line react/function-component-definition
function ServicesPage() {
  const [loading, setLoading] = useState(true);
  const [servicesChunks, setServicesChunks] = useState();

  const navigate = useNavigate();
  const { role } = useUserAccess();

  useEffect(() => {
    getAllServices()
      .then(response => {
        mountServices(response);
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  const mountServices = services => {
    const chunkSize = 4;
    const chunks = [];
    for (let i = 0; i < services.length; i += chunkSize) {
      chunks.push(services.slice(i, i + chunkSize));
    }
    setServicesChunks(chunks);
  };
  const gridStyle = {
    width: "100%",
    textAlign: "center",
    margin: "0 auto",
  };

  const loadLogo = service => {
    let { logo } = service;

    if (service.logo.includes("image/svg")) {
      logo = service.logo.replace("data:image/svg;base64, ", "data:image/svg+xml;base64,");
    }

    return <img alt={service.name} className={styles.image} src={`${logo}`} />;
  };

  const mountCards = services =>
    services.map(service => (
      <Col key={service.id} xs={{ span: 24, order: 2 }} xl={{ span: 4, order: 1 }}>
        <div>
          <Card hoverable className={styles.card}>
            <Card.Grid onClick={() => goToService(service.id)} style={gridStyle}>
              <Popover content={service.name}>{loadLogo(service)}</Popover>
              <div className={styles.ant_card_meta}>
                <Card.Meta title={service.alias} />
              </div>
            </Card.Grid>
          </Card>
        </div>
      </Col>
    ));

  const goToService = serviceId => {
    navigate(`/services/${serviceId}`);
  };

  return (
    <PageLayout title="Serviços" subTitle="Explore os serviços disponibilizados pela LIS.">
      {role === "superadmin" && (
        <div className={styles.link_manual}>
          <Button type="link" href="/manual/services">
            <QuestionCircleOutlined /> Visite a documentação para criação de novos serviços
          </Button>
        </div>
      )}
      <Spin className={styles.services_spinner} spinning={loading} size="large" />
      <div className={styles.services_listing}>
        {servicesChunks &&
          servicesChunks.map((values, idx) => (
            <Row key={idx} gutter={[8, 48]} justify="center">
              {mountCards(values)}
            </Row>
          ))}
      </div>
    </PageLayout>
  );
}

export { ServicesPage };
