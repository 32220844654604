/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import "./widget.styles.scss";
import React from "react";
import { Divider, Typography } from "antd";
import CodeEditor from "rmce";
import { starterTemplate } from "./constants";
import { WidgetSection } from "./WidgetSection";

function Widget({ title, sections }) {
  const { Title } = Typography;

  return (
    <>
      <Title level={3} className="card-title">
        {title || "Instruções gerais de uso"}
      </Title>
      <p>
        Provemos alguns exemplos de implementação dos serviços disponibilizados. Para facilitar a
        visualização e compreensão, os códigos estão separados em: HTML, JavaScript e CSS.
        <br />
        Essas implementações permitem explorar a mecânica de utilização dos serviços e a sua
        personalização.
      </p>
      <p>
        Antes de utilizar os exemplos abaixo, tenha certeza que seu código está de acordo com os
        padrões de desenvolvimento em HTML5.
        <br />
        Então, copie e cole os códigos nos locais correspondentes. Sinta-se livre para modificar e
        adaptar as implementações.
      </p>

      <CodeEditor width="100%" language="html" className="rmce" value={starterTemplate} readOnly />
      <p>
        O usuário precisa ter uma conta com token ativo para ter acesso aos serviços.
        <br />
        No código JavaScript "&lt;script&gt;", apresentado nos widgets abaixo, você deverá
        substituir o placeholder &lt;ACCESS_TOKEN&gt; pelo seu token de aplicação.
      </p>
      {sections !== null &&
        sections.map((section, index) => (
          <div key={index}>
            <Divider />
            <WidgetSection
              title={section.title}
              description={section.description}
              apiUrl={section.apiUrl}
              documentId={section.documentId}
              snippets={section.snippets}
            />
          </div>
        ))}
    </>
  );
}

export { Widget };
