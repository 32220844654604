/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useEffect } from "react";
import styles from "./TemplateModal.module.scss";

const selectTypeOptions = [
  {
    label: "Textual",
    value: "text",
  },
  {
    label: "Data",
    value: "date",
  },
  {
    label: "Inteiro",
    value: "integer",
  },
  {
    label: "Float",
    value: "float",
  },
  {
    label: "Currency",
    value: "currency",
  },
];

const normalizeFieldName = fields =>
  fields.map(field => ({
    ...field,
    field_name: field.field_name.trim().replace(/\s/g, "_"),
  }));

function TemplateModal({
  visible,
  onClose,
  onConfirm,
  loading,
  isEditMode,
  initialTemplateName,
  initialValues,
  selectedTemplate,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const onFinish = async values => {
    const finalFormValues = {
      name: values.name,
      template_fields: normalizeFieldName(values.fields),
    };
    if (isEditMode) {
      await onConfirm(finalFormValues, selectedTemplate.id);
      form.resetFields();
    } else {
      await onConfirm(finalFormValues);
      form.resetFields();
    }
  };

  // TODO: Verificar initialValue of Form antd
  useEffect(() => {
    if (initialTemplateName) {
      form.setFieldsValue(initialTemplateName);
    }
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
    if (!isEditMode) {
      form.resetFields();
    }
  }, [initialValues, isEditMode]);

  return (
    <Modal
      title={isEditMode ? "Editar template" : "Criar template"}
      visible={visible}
      width={720}
      getContainer={false}
      forceRender
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={[
        <Button
          type="primary"
          form="dynamic_form_nest_item"
          key="submit"
          htmlType="submit"
          disabled={loading}
        >
          {isEditMode ? "Salvar" : "Criar"}
        </Button>,
        <Button
          key="back"
          disabled={loading}
          onClick={() => {
            form.resetFields();
            onClose();
          }}
        >
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
        <Form.Item
          name="name"
          label="Nome do template"
          type="text"
          className={styles.custom_form_padding}
          rules={[
            {
              required: true,
              message: "Insira um nome para o template!",
            },
          ]}
        >
          <Input id="name" name="name" type="text" />
        </Form.Item>
        <Divider />
        <Typography.Paragraph style={{ fontWeight: "bold", fontSize: 18 }}>
          Campos
        </Typography.Paragraph>
        <Form.List name="fields">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={2}>
                  <Col span={11}>
                    <Form.Item
                      {...restField}
                      name={[name, "field_name"]}
                      label="Nome"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                      rules={[{ required: true, message: "Nome obrigatório" }]}
                    >
                      <Input placeholder="Nome" autoFocus />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "field_type"]}
                      label="Tipo"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                      rules={[{ required: true, message: "Tipo obrigatório" }]}
                    >
                      <Select placeholder="Selecione o tipo de campo">
                        {selectTypeOptions.map(option => (
                          <Option key={option.label} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <div className={styles.container_icon_Remove_form}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Adicionar novo campo
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
}

export { TemplateModal };
