/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin } from "antd";
import { getAllModules, getModulesRelatedByJob } from "api/Etl";
import { TagRender } from "components/TagRender/TagRender";
import React, { useMemo, useState, useEffect } from "react";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { CronLocalePtBR } from "utils/constants/CronLocale-PtBR";
import { useCronReducer } from "utils/useReducerCron";
import styles from "./JobsModal.module.scss";

const { Option } = Select;

function JobsModal({ open, onClose, onConfirm, loading, job = undefined }) {
  const [modules, setModules] = useState([]);
  const defaultCronValue = "30 4 * * *";
  const [loadingModules, setLoadingModules] = useState(loading);
  const [values, dispatchValues] = useCronReducer(defaultCronValue);
  const [error, onError] = useState(undefined);

  const fetchModules = () => {
    getAllModules().then(response => {
      setModules(response);
    });
  };

  useEffect(() => {
    fetchModules();
  }, [open]);

  const [form] = Form.useForm();

  useEffect(async () => {
    setLoadingModules(true);
    if (job) {
      const modulesList = await getModulesRelatedByJob(job.id).then(response => {
        setLoadingModules(false);
        const allModulesIds = modules.map(module => module.id);
        return [
          ...new Set(
            response
              .filter(module => allModulesIds.indexOf(module.module_id) !== -1)
              .map(module => module.module_id),
          ),
        ];
      });
      form.setFieldsValue({
        alias: job.alias,
        description: job.description,
        modules: modulesList.map(module => module),
      });
      dispatchValues({ type: "SET_VALUES", value: job.cron_expression });
    } else {
      form.resetFields();
      dispatchValues({ type: "SET_VALUES", value: defaultCronValue });
      setLoadingModules(false);
    }
  }, [job]);

  const onFinish = data => {
    onConfirm(data, values.cronValue);
    form.resetFields();
    onClose();
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const modulesOptions = useMemo(
    () =>
      modules.map(module => (
        <Option key={module.id} value={module.id}>
          {module.alias}
        </Option>
      )),
    [modules],
  );

  return (
    <Modal
      title={job != null ? "Editar Job" : "Criar Job"}
      visible={open}
      width={800}
      getContainer={false}
      forceRender
      onCancel={handleClose}
      footer={[
        <Button
          type="primary"
          key="submit"
          form="job-creation-form"
          htmlType="submit"
          disabled={loading}
        >
          {job != null ? "Salvar" : "Criar"}
        </Button>,
        <Button key="back" disabled={loading} onClick={handleClose}>
          Cancelar
        </Button>,
      ]}
    >
      {!loadingModules ? (
        <Form form={form} layout="horizontal" name="job-creation-form" onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="alias"
                label="Alias"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                tooltip="Nome que remeta a aplicação que o job irá ser utilizado"
                rules={[{ required: true, message: "Insira o Alias do Job" }]}
              >
                <Input placeholder="Alias" id="alias" name="alias" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Descrição"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.custom_form_padding}
                tooltip="Descrição que remeta a aplicação que o job irá ser utilizado"
                rules={[{ required: true, message: "Insira a descrição do Job" }]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Descrição"
                  id="description"
                  name="description"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p htmlFor="command" className={styles.cron_label}>
                Expressão cron
              </p>
              <Input
                placeholder="Comando"
                id="command"
                name="command"
                status={error ? "error" : ""}
                value={values.inputValue}
                onChange={event => {
                  dispatchValues({
                    type: "SET_INPUT_VALUE",
                    value: event.target.value,
                  });
                }}
                onBlur={() => {
                  dispatchValues({
                    type: "SET_CRON_VALUE",
                    value: values.inputValue,
                  });
                }}
                onPressEnter={() => {
                  dispatchValues({
                    type: "SET_CRON_VALUE",
                    value: values.inputValue,
                  });
                }}
              />
              {error ? (
                <span className={styles.msg_error}>{error?.description || "Valor inválido"}</span>
              ) : null}
              <div className={styles.mt_12}>
                <Cron
                  locale={CronLocalePtBR}
                  value={values.cronValue}
                  setValue={newValue => {
                    dispatchValues({
                      type: "SET_VALUES",
                      value: newValue,
                    });
                  }}
                  onError={onError}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="modules"
                label="Módulos"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                tooltip="Módulos que o job irá executar"
                rules={[{ required: true, message: "Selecione pelo menos um módulo" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  tagRender={TagRender}
                  style={{ width: "100%" }}
                  placeholder="Selecione os módulos"
                >
                  {modulesOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin className={styles.services_spinner} spinning={loadingModules} size="large" />
      )}
    </Modal>
  );
}

export { JobsModal };
