/* eslint-disable import/no-unresolved */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Button, Checkbox, Col, DatePicker, Form, message, Modal, Row } from "antd";
import { getJobExecutionLog } from "api/Etl";
import React, { useState, useEffect } from "react";

function LogsModal({ open, onClose, job, onConfirm }) {
  const [AllLogsChecked, setAllLogsChecked] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (open) {
      form.resetFields();
      setAllLogsChecked(false);
    }
  }, [open]);

  const onFinish = data => {
    getJobExecutionLog(job.id).then(() => {
      message.info("Download iniciado");
    });
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Download dos logs"
      visible={open}
      width={450}
      getContainer={false}
      forceRender
      onCancel={onClose}
      footer={[
        <Button type="primary" key="submit" form="download-log-form" htmlType="submit">
          Download
        </Button>,
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} layout="horizontal" name="download-log-form" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <p>Selecione o período dos logs para realizar o download</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              name="date_init"
              label="De"
              rules={[
                {
                  required: AllLogsChecked ? false : true,
                  message: "Insira a data inicial",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Data inicial"
                disabled={AllLogsChecked}
                id="date_init"
                name="date_init"
                disabledDate={date => !date || date.isAfter(Date.now())}
                type="text"
              />
            </Form.Item>
          </Col>
          <Col span={4} />
          <Col span={10}>
            <Form.Item
              name="date_end"
              label="até"
              rules={[
                {
                  required: AllLogsChecked ? false : true,
                  message: "Insira a data final",
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Data final"
                disabled={AllLogsChecked}
                id="date_end"
                name="date_end"
                disabledDate={date => !date || date.isAfter(Date.now())}
                type="text"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="allTime" label="Todos os logs disponíveis" valuePropName="checked">
              <Checkbox onChange={e => setAllLogsChecked(e.target.checked)} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export { LogsModal };
