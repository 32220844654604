/* eslint-disable react/prop-types */
import { DynamicFormInput } from "../DynamicFormInput/DynamicFormInput";
import styles from "./DynamicForm.module.scss";

function DynamicForm({ templateFields }) {
  return (
    <div className={styles.container_center}>
      {templateFields.map((field, index) => (
        <DynamicFormInput key={index} field={field} />
      ))}
    </div>
  );
}

export { DynamicForm };
