/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import moment from "moment";

import React, { useEffect, useState } from "react";
import { message, Spin, Button, Modal, Form, DatePicker, Row, Col } from "antd";
import styles from "./TokenModalEditExpirationDate.module.scss";
import { editToken, getAllTokens } from "../../../api/Tokens/tokens.service";

// eslint-disable-next-line react/function-component-definition
const TokenModalEditExpirationDate = ({ isVisible, closeModal, mountTable, token }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const formRef = React.createRef();

  useEffect(() => {
    if (isVisible) {
      formRef?.current.setFieldsValue({
        expiration_date: moment(token?.expiration_date, "DD-MM-YYYY"),
      });
    }
  }, [isVisible]);

  const refresh = () => {
    getAllTokens()
      .then(response => {
        mountTable(response);
      })
      .finally();
  };

  const onFinish = data => {
    setLoading(true);

    data["expiration_date"] = data["expiration_date"].format("YYYY-MM-DD[T]HH:mm:ss");
    console.log(JSON.stringify(data));

    setIsCreating(true);
    editToken(token?.id, JSON.stringify(data))
      .then(() => {
        message.success("Token criado com sucesso!");
        closeModal();
        setLoading(false);
        refresh();
      })
      .catch(err => {
        message.error(err.message);
      })
      .finally(() => setIsCreating(false));
  };

  const onFinishFailed = data => {
    // eslint-disable-next-line no-console
    console.log("onFinishFailed data", data);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      title={`Data de expiração de ${token?.alias}`}
      visible={isVisible}
      onCancel={() => closeModal()}
      footer={[
        <Button disabled={isCreating} type="primary" form="myForm" key="submit" htmlType="submit">
          <Spin spinning={loading} size="small" />
          Alterar
        </Button>,
        <Button disabled={isCreating} key="back" onClick={() => closeModal()}>
          Cancelar
        </Button>,
      ]}
    >
      <Form
        name="myForm"
        layout="horizontal"
        ref={formRef}
        initialValues={{
          expiration_date: moment(token?.expiration_date, "DD-MM-YYYY"),
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Data de expiração"
              name="expiration_date"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={styles.custom_form_padding}
              rules={[{ required: true }]}
            >
              <DatePicker
                className={styles.data_placeholder_input}
                disabled={isCreating}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export { TokenModalEditExpirationDate };
