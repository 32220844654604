/* eslint-disable react/prop-types */
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, List, Row } from "antd";
import styles from "./FilesList.module.scss";

function FilesList({ acceptFiles, onDelete }) {
  return (
    acceptFiles.length > 0 && (
      <Row className={styles.row_margin_top}>
        <Col span={24}>
          <div className={styles.div_padding}>
            <div>
              <p className={styles.paragraph}>Arquivos adicionados:</p>
            </div>
            <List size="small">
              {acceptFiles.map((file, idx) => (
                <List.Item
                  key={idx}
                  actions={[
                    <Button
                      type="ghost"
                      shape="circle"
                      key={`delete-${idx}`}
                      onClick={() => onDelete(file)}
                    >
                      <DeleteOutlined className={styles.delete_outlined_color} />
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={file.name} />
                </List.Item>
              ))}
            </List>
          </div>
        </Col>
      </Row>
    )
  );
}

export { FilesList };
