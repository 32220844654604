// eslint-disable-next-line import/no-unresolved
import { middlewareDefault } from "api/middleware";

const urlBase = window._env_.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_URL;

async function getAllUsers() {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/auth/user/`, requestOptions);
  return await middlewareDefault(response);
}

async function createUsers(data) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    body: data,
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/auth/user`, requestOptions);
  return await middlewareDefault(response);
}

async function deleteUser(id) {
  const token = window.localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${urlBase}/auth/user/${id}`, requestOptions);
  return await middlewareDefault(response);
}

export { getAllUsers, createUsers, deleteUser };
